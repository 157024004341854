<div *ngIf="preview == 0 && process" class="display-inline">
  <div class="row-flex-flow margin-5">
    <div class="proc-def-upld-div">
      <mat-icon class="dataIO-upld-icon">{{isExport ? 'download' : 'upload'}}</mat-icon>
    </div>
    <div class="dataIO-schedule-div">
      <mat-icon *ngIf="automate" class="dataIO-automatedIcon">schedule</mat-icon>
    </div>
    <div class="comm-edtr-name-container">
      <div><strong>{{process.Name}}</strong></div>
      <div><span class="sub-title">({{process.ProcessId}})&nbsp;</span>Modified: {{process.Amended | date:'shortDate': '' : translate.currentLang}} {{process.Amended | date:'mediumTime': '' : translate.currentLang}}</div>
      <div *ngIf="startOffset != null">Starting {{startOffset | date:'short': '' : translate.currentLang }}, {{ cronBasicDescription }} until {{endOffset | date:'short': '' : translate.currentLang }}&nbsp;&nbsp;</div>
      <div>{{errors.length > 0 ? errors.length + (process.StatusDescription == "Idle" ? " Previous" : "") + " Errors" : "There are no errors"}}</div>
    </div>
    <div class="column-flex-flow">
      <div class="row-flex-flow">
        <div class="proc-def-actionBtns-container">
          <strong>{{type()}}</strong>
        </div>
        <div class="proc-def-status-div">
          <div><strong>{{process.StatusDescription}}</strong>&nbsp;{{process.StatusDescription == 'In progress' ?'(' + process.Progress + '%)' : ''}}</div>
        </div>
      </div>
      <div class="row-flex-flow">
        <div class="proc-def-actionBtns-container">
          &nbsp;
        </div>
        <div class="row-flex-flow proc-def-actionBtns-container">
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Edit Definition" (click)="editDefinition(1)"><mat-icon>edit</mat-icon></button>
          </div>
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="View History" (click)="editDefinition(2)"><mat-icon>history</mat-icon></button>
          </div>
          <div *ngIf="isQuotas()" class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="View Quotas" (click)="editDefinition(7)"><mat-icon>track_changes</mat-icon></button>
          </div>
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Copy Definition" (click)="editDefinition(3)"><mat-icon>content_copy</mat-icon></button>
          </div>
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Abandon Definition" (click)="editDefinition(6)"><mat-icon>timer_off</mat-icon></button>
          </div>
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Delete Definition" (click)="editDefinition(4)"><mat-icon>delete</mat-icon></button>
          </div>
          <div class="proc-def-actionBtn-div">
            <button mat-mini-fab color="accent" matTooltip="Export Definition" (click)="editDefinition(5)"><mat-icon>download</mat-icon></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
</div>
<div *ngIf="preview == 1" [@detailExpand]="preview == 1 ? 'expanded' : 'collapsed'">
  <ng-container *ngTemplateOutlet="editorButtons"></ng-container>
  <div [formGroup]="summaryFormGroup" style="background-color: white;padding:5px">
    <div>
      <mat-form-field appearance="outline" class="fullWidth">
        <mat-label>Name ({{process == null || process.ProcessId == 0 ? 'New' : process.ProcessId}})</mat-label>
        <input matInput placeholder="Name" formControlName="Name">
      </mat-form-field>
    </div>
  </div>
  <mat-stepper orientation="vertical" [linear]="true" #stepper>
    <mat-step [stepControl]="firstFormGroup">
      <div [formGroup]="firstFormGroup">
        <ng-template matStepLabel>{{isExport ? 'Destination' : 'Source'}}</ng-template>
        <p *ngIf="isExport">Choose where you want to send the data</p>
        <p *ngIf="!isExport">Choose where you want to get the data from</p>
        <mat-button-toggle-group #group="matButtonToggleGroup" (change)="updateTransferFormat($event)" formControlName="TransferFormat">
          <mat-button-toggle value="SFTP">SFTP</mat-button-toggle>
          <mat-button-toggle value="FTPS">FTPS</mat-button-toggle>
          <mat-button-toggle value="S3">S3</mat-button-toggle>
          <mat-button-toggle *ngIf="isExport" value="SPECIFIC">DOWNLOAD</mat-button-toggle>
          <mat-button-toggle *ngIf="!isExport && !automate" value="SPECIFIC">UPLOAD</mat-button-toggle>
          <mat-button-toggle *ngIf="isExport" value="EMAIL">EMAIL</mat-button-toggle>
        </mat-button-toggle-group>
        <div>
          &nbsp;
        </div>
        <div *ngIf="transfer('SFTP') || transfer('FTPS')">
          <app-ftp-setup #ftpSetup [fileTransfer]="process.Task.FileTransfer"></app-ftp-setup>
        </div>
        <div *ngIf="transfer('EMAIL')">
          <h3>Email Data</h3>
          <div class="alert alert-warning" role="alert">
            Emailing data is not a recommended approach to share what might be confidential data.
          </div>
        </div>
        <div *ngIf="!isExport && transfer('SPECIFIC')" class="mt-5">
          <app-file-upload #fileUpload accept=".*" text="Import File" (uploaded)="onFileUploaded($event)"></app-file-upload>
        </div>
        <div *ngIf="transfer('S3')" class="mt-5">
          <app-s3-setup #s3Setup [fileTransfer]="process.Task.FileTransfer"></app-s3-setup>
        </div>
        <div *ngIf="!isExport && !transfer('SPECIFIC') && !transfer('EMAIL')">
          <div>
            <p>
              The files downloaded will automatically be deleted from your file storage source.<br />
              If you want to archive the file instead, provide the folder and naming convention you wish to use.
            </p>
            <mat-form-field appearance="outline" class="fullWidth">
              <mat-label>Archive Folder and Name</mat-label>
              <input matInput placeholder="Folder and Name" formControlName="Archive">
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <div [formGroup]="secondFormGroup">
        <ng-template matStepLabel>What data do you want to {{ isExport ? 'export' : 'import' }}?</ng-template>
        <mat-button-toggle-group #group="matButtonToggleGroup" formControlName="DataType">
          <mat-button-toggle *ngIf="hasCompleted()" value="Completed">Completed</mat-button-toggle>
          <mat-button-toggle *ngIf="hasCompleted()" value="Partial">Partial</mat-button-toggle>
          <mat-button-toggle *ngIf="hasInvites() || isOneWay()" value="Invites">{{ isOneWay() ? 'Invites' : 'Messages'}}</mat-button-toggle>
          <mat-button-toggle *ngIf="hasAnomalies()" value="Anomalies">Anomalies</mat-button-toggle>
          <mat-button-toggle *ngIf="hasStops()" value="Stops">Stops</mat-button-toggle>
          <mat-button-toggle *ngIf="!isExport" value="SurveyPanel">Panel</mat-button-toggle>
          <mat-button-toggle *ngIf="!isExport" value="Results">Results</mat-button-toggle>
        </mat-button-toggle-group>
        <div>
          &nbsp;
        </div>
        <div *ngIf="dataSource('Invites') && isOneWay()">
          <h3>Invitation Only Surveys</h3>
          <p>We are sending out your invitations to surveys.</p>
        </div>
        <div *ngIf="dataSource('Invites') && !isOneWay()">
          <h3>Messages Data</h3>
          <p>Inbound and outbound messages that  make up the surveys questions and answers together with the statuses</p>
          <mat-list div class="list" [formGroup]="formGroup">
            <mat-list-item class="proc-def-msgData-listItem">
              <div matLine class="proc-def-msgData-listItem-content">
                <mat-form-field appearance="outline" class="proc-def-filtByCntry-field">
                  <mat-label>Filter by Country</mat-label>
                  <mat-select formControlName="Country">
                    <mat-option value="">All Countries</mat-option>
                    <mat-option *ngFor="let country of countries" [value]="country">{{country.Country}}</mat-option>
                  </mat-select>
                </mat-form-field>&nbsp;
                <mat-form-field appearance="outline" class="proc-def-filtByNum-field">
                  <mat-label>Filter by a number</mat-label>
                  <input matInput placeholder="Number" formControlName="Number">
                </mat-form-field>&nbsp;
                <mat-form-field appearance="outline" class="proc-def-dirn-field">
                  <mat-label> Direction </mat-label>
                  <mat-select formControlName="Direction">
                    <mat-option value="0">Both</mat-option>
                    <mat-option value="1">Inbound</mat-option>
                    <mat-option value="2">Outbound</mat-option>
                  </mat-select>
                </mat-form-field>&nbsp;
                <mat-form-field appearance="outline">
                  <mat-label>Receipt Status</mat-label>
                  <mat-select formControlName="Status" #statuses multiple>
                    <mat-option value="0" (click)="selectStatus('0')">All</mat-option>
                    <mat-option value="1" (click)="selectStatus('1')">Delivered</mat-option>
                    <mat-option value="8" (click)="selectStatus('8')">Read</mat-option>
                    <mat-option value="5" (click)="selectStatus('5')">Invalid Number</mat-option>
                    <mat-option value="6" (click)="selectStatus('6')">Black Listed</mat-option>
                    <mat-option value="7" (click)="selectStatus('7')">Blocked</mat-option>
                    <mat-option value="2" (click)="selectStatus('2')">Submitted to Carrier</mat-option>
                    <mat-option value="3" (click)="selectStatus('3')">Failed</mat-option>
                    <mat-option value="4" (click)="selectStatus('4')">Unknown</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field appearance="outline" class="proc-def-filtByMsgCont-field">
                <mat-label>Filter by message content</mat-label>
                <input matInput placeholder="Content" formControlName="Content">
              </mat-form-field>
            </mat-list-item>
          </mat-list>
        </div>
        <div *ngIf="dataSource('Completed')">
          <h3>Completed Survey Data</h3>
          <p>Completed survey data only, leaving partially completed surveys untouched.</p>
        </div>
        <div *ngIf="dataSource('Partial')">
          <h3>Partial Survey Data</h3>
          <p>Partial Survey data can be refined by applying a filter.</p>
          <app-advanced-routing [display]="false" [rules]="rule" [showAddRemove]="false" name="Export Filter " class="fullWidth"></app-advanced-routing>
          <br />
        </div>
        <div *ngIf="dataSource('Anomalies')">
          <h3>Anomalies</h3>
          <p>Answers to SMS surveys can be anomallies that you can review and improve on your scripting to provide the respondent with a better experience.</p>
        </div>
        <div *ngIf="dataSource('Stops')">
          <h3>Stopped Surveys</h3>
          <p>SMS surveys can be stopped by the respondent. Get a list of those that have stopped you sending further surveys.</p>
        </div>
        <div *ngIf="dataSource('SurveyPanel')">
          <h3>Panel Data</h3>
          <p>Panel data can be imported into a running survey to continue to boost the feedback data you are looking for.</p>
        </div>
        <div *ngIf="dataSource('Results')">
          <h3>Results Data</h3>
          <p>Results data from another survey can be imported and merged with your survey's results for further analysis.</p>
          <br />
        </div>
        <mat-button-toggle-group #group="matButtonToggleGroup" (change)="updateFormat($event)" formControlName="Format">
          <mat-button-toggle value="CSV">CSV</mat-button-toggle>
          <mat-button-toggle value="TSV">TSV</mat-button-toggle>
          <mat-button-toggle value="EXCEL">Excel</mat-button-toggle>
          <mat-button-toggle value="PIPE">PIPE</mat-button-toggle>
          <mat-button-toggle value="FIXED">Fixed</mat-button-toggle>
          <mat-button-toggle value="JSON">JSON</mat-button-toggle>
          <mat-button-toggle value="SPSS">SPSS</mat-button-toggle>
        </mat-button-toggle-group>
        <div>
          &nbsp;
        </div>
        <div *ngIf="format('CSV')">
          <h3>Comma separated values</h3>
          <p>
            A Comma Separated Values (CSV) file is a plain text file that contains a list of data. These files are often used for exchanging data between different applications. For example, databases and contact managers often support CSV files.
            These files may sometimes be called Character Separated Values or Comma Delimited files. They mostly use the comma character to seperate (or delimit) data, but sometimes use other characters, like semicolons. The idea is that you can export complex data from one application to a CSV file, and then import the data in that CSV file into another application.
          </p>
        </div>
        <div *ngIf="format('TSV')">
          <h3>Tab separated values</h3>
          <p>
            TSV stands for Tab Separated Values. Files with the .tsv extension store information in data tables, with each column of data being separated by tabs. The TSV file format allows the data tables to be imported into most spreadsheet applications, allowing for the interchange of spreadsheets between different software applications. The TSV files are similar to CSV files, but tabs instead of commas seperate the data.
          </p>
        </div>
        <div *ngIf="format('EXCEL')">
          <h3>Microsoft Excel</h3>
          <p>
            Microsoft Excel Open XML Spreadsheet (XLSX) file created by Microsoft Excel. You can also open this format in other spreadsheet apps, such as Apple Numbers, Google Docs, and OpenOffice. They are stored as a compressed Zip file, which contains a bunch of other files used to open the document.
          </p>
        </div>
        <div *ngIf="format('JSON')">
          <h3>JSON</h3>
          <p>
            A JSON file is a JSON (JavaScript Object Notation) data file. It contains a formatted dataset that is intended to be processed by a website, web application, or software program. JSON files can be thought of as text-based databases.
          </p>
        </div>
        <div *ngIf="format('PIPE')">
          <h3>Pipe separated values</h3>
          <p>
            A PIPE file is sometimes required where comma separated files do not work.
          </p>
        </div>
        <div *ngIf="format('FIXED')">
          <h3>Fixed File Format</h3>
          <p>
            A fixed file format is a custom format that must be clearly defined using our file format editor.
          </p>
        </div>
        <div *ngIf="format('SPSS')">
          <h3>SPSS (SAV) Format</h3>
          <p>
            SPSS (SAV) is capable of representing all the data types used in SPSS, a widely used software system for statistical analysis.
          </p>
        </div>
        <mat-accordion *ngIf="isExport">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                More Options
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
              <div>
                <mat-form-field appearance="outline">
                  <mat-label>Date Filter</mat-label>
                  <mat-select (selectionChange)="updateDateRange($event)" formControlName="AllData">
                    <mat-option value="all">Export all data</mat-option>
                    <mat-option value="between">Export data between two times</mat-option>
                    <mat-option value="from">Export data from a certain time</mat-option>
                  </mat-select>
                </mat-form-field>&nbsp;
                <div *ngIf="!process.Task.AllData" class="row-flex">
                  <app-advanced-timings placeholder="From" [(value)]="startDate"></app-advanced-timings>
                  <app-advanced-timings *ngIf="!process.Task.From" placeholder="To" label="-" [(value)]="endDate"></app-advanced-timings>
                </div>
              </div>
              <div *ngIf="hasCompleted()">
                <mat-radio-group aria-label="Select an option" formControlName="Options">
                  <mat-radio-button value="1">Use numeric values</mat-radio-button>&nbsp;&nbsp;
                  <mat-radio-button value="2">Use choice text</mat-radio-button>
                </mat-radio-group>
              </div>
              <div>
                <mat-checkbox class="example-margin" formControlName="Compress">Compress data into zip file</mat-checkbox>
              </div>
              <div>
                <mat-checkbox class="example-margin" formControlName="LineBreaks">Remove line breaks</mat-checkbox>
              </div>
              <div *ngIf="hasCompleted()">
                <mat-checkbox class="example-margin" formControlName="Split">Split multi-value fields into columns</mat-checkbox>
              </div>
              <div>
                <mat-checkbox class="example-margin" formControlName="System">Include system data</mat-checkbox>
              </div>
              <div>
                <mat-checkbox class="example-margin" formControlName="Respondents" (change)="updateHeader($event)">Include respondent data</mat-checkbox>
              </div>
              <div *ngIf="hasCompleted()">
                <mat-checkbox class="example-margin" formControlName="Timings">Include data collection timings</mat-checkbox>
              </div>
              <div>
                <mat-checkbox class="example-margin" formControlName="Header" (change)="updateHeader($event)">Include column headings</mat-checkbox>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-accordion *ngIf="!isExport">
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                More Options
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
              <div>
                <mat-checkbox class="example-margin" formControlName="Compress">Uncompress data from zip file</mat-checkbox>
              </div>
              <div>
                <mat-checkbox class="example-margin" formControlName="Header" (change)="updateHeader($event)">Headings included</mat-checkbox>
              </div>
              <div>
                <h3>Country Code Prefix</h3>
                <p>
                  If you are intending to use our messaging services and your mobile numbers do not include a country prefix, you need to supply a prefix we can apply to your data.
                </p>
                <mat-form-field appearance="outline">
                  <mat-label>Country Prefix</mat-label>
                  <input matInput placeholder="Country Prefix" formControlName="CountryPrefix">
                </mat-form-field>
              </div>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Error Handling Options
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <div>
              <mat-button-toggle-group #group="matButtonToggleGroup" (change)="updateErrorHandling($event)" formControlName="ErrorHandling">
                <mat-button-toggle value="full">Full</mat-button-toggle>
                <mat-button-toggle value="partial">Partial</mat-button-toggle>
              </mat-button-toggle-group>
            </div>
            <div>
              &nbsp;
            </div>
            <div *ngIf="errorType('full')">
              <h3>Full File Processing </h3>
              <p>
                Only move the data into the system if it can all be successfully processed.
              </p>
            </div>
            <div *ngIf="errorType('partial')">
              <h3>Partial File Processing </h3>
              <p>
                Move any data into the system that can be successfully processed. Any data that is invalid can be reported using the following options...
              </p>
            </div>
            <div>
              <mat-checkbox class="example-margin" formControlName="ReportErrors">Report Any Errors</mat-checkbox>
              <div *ngIf="reportErrors()">
                <p>Select the best way to get your errors reported</p>
                <mat-button-toggle-group #group="matButtonToggleGroup" (change)="updateTransferFormateh($event)" formControlName="TransferFormateh">
                  <mat-button-toggle value="SFTP">SFTP</mat-button-toggle>
                  <mat-button-toggle value="FTPS">FTPS</mat-button-toggle>
                  <mat-button-toggle value="S3">S3</mat-button-toggle>
                  <mat-button-toggle value="SPECIFIC">DOWNLOAD</mat-button-toggle>
                  <mat-button-toggle value="EMAIL">EMAIL</mat-button-toggle>
                </mat-button-toggle-group>
                <div>
                  &nbsp;
                </div>
                <div *ngIf="transfereh('SFTP') || transfereh('FTPS')">
                  <app-ftp-setup #ftpSetupeh [fileTransfer]="process.Task.ErrorHandlingFileTransfer"></app-ftp-setup>
                </div>
                <div *ngIf="transfereh('EMAIL')">
                  <h3>Email Data</h3>
                  <div class="alert alert-warning" role="alert">
                    Emailing data is not a recommended approach to share what might be confidential data.
                  </div>
                </div>
                <div *ngIf="transfereh('S3')" class="mt-5">
                  <app-s3-setup #s3Setupeh [fileTransfer]="process.Task.ErrorHandlingFileTransfer"></app-s3-setup>
                </div>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
        <p></p>
        <mat-button-toggle-group #group="matButtonToggleGroup" (change)="updateStandard($event)" formControlName="Standard">
          <mat-button-toggle value="Standard">Standard</mat-button-toggle>
          <mat-button-toggle value="Custom">Custom</mat-button-toggle>
        </mat-button-toggle-group>
        <div *ngIf="custom()" class="mt-5">
          <app-custom-format [(format)]="customFormat" [task]="process.Task" [questions]="dataSource('Invites') ? [] : questions" [templateProfile]="templateProfile"></app-custom-format>
        </div>
        <p>
        </p>
      </div>
    </mat-step>
    <mat-step *ngIf="!isExport">
      <ng-template matStepLabel>Import Rules</ng-template>
      <p>
        Refine your import with our available rule settings
      </p>
      <div [formGroup]="rulesFormGroup">
        <div>
          <mat-form-field appearance="outline">
            <mat-label>Number Lookup Settings</mat-label>
            <mat-select formControlName="NumberLookup">
              <mat-option [value]="0">Do not check any numbers</mat-option>
              <mat-option [value]="1">Check that the number is valid</mat-option>
              <mat-option [value]="2">Check that the number is mobile</mat-option>
            </mat-select>
          </mat-form-field>&nbsp;
        </div>
        <!--div>
          <mat-checkbox class="example-margin" formControlName="Dedupe">Apply Dedeuplication Rules</mat-checkbox>
        </div>
        <div *ngIf="isDedupe()">
          <h3>Deduplication Rules</h3>
          <p>
            Cleanse your data.
          </p>
        </div-->
        <div>
          <mat-checkbox class="example-margin" (change)="updateQuota($event)" formControlName="Quotas">Apply Quota Rules</mat-checkbox>
        </div>
        <div *ngIf="isQuotas()" class="fullWidth">
          <h3>Import Quotas</h3>
          <p>
            Control the number of recipients imported into a panel and what happens when those quotas are reached.
            <strong>Current number of recipients: {{quotaTotal < 1 ? 0 : quotaTotal}}</strong>
          </p>
          <div class="column-flex">
            <div style="border: 1px solid #ccc;border-radius:5px">
              <app-quota-definitions [quotas]="quotas" [quotaTotal]="quotaTotal" [variables]="variables"></app-quota-definitions>
            </div>
          </div>
        </div>
        <div>
          <mat-checkbox class="example-margin" formControlName="Custom">Apply Custom Rules</mat-checkbox>
        </div>
        <div *ngIf="isCustom()">
          <h3>Custom Import Capabilities</h3>
          <p>
            Plug in your own capability to handle custom rules and data transformations. Enter a URL that can receive our recipient data and process it.
            For more information please visit <a target="_blank" href="https://apidocs.diysurveys.com/custom-transformations">https://apidocs.diysurveys.com/custom-transformations</a>.
          </p>
          <div class="column-flex">
            <div style="border: 1px solid #ccc;border-radius:5px;padding:10px 20px">
              <div>
                <mat-form-field appearance="outline" class="fullWidth">
                  <mat-label>Custom URL</mat-label>
                  <input matInput placeholder="Custom URL" formControlName="CustomUrl">
                </mat-form-field>
              </div>
              <p>Select the most appropriate masking level to apply</p>
              <mat-button-toggle-group #group="matButtonToggleGroup" (change)="updateMaskingLevel($event)" formControlName="MaskingLevel">
                <mat-button-toggle [value]="0">0 - None</mat-button-toggle>
                <mat-button-toggle [value]="1">1 - Mobile</mat-button-toggle>
                <mat-button-toggle [value]="2">2 - Email</mat-button-toggle>
                <mat-button-toggle [value]="3">3 - Mobile &amp; Email</mat-button-toggle>
                <mat-button-toggle [value]="4">4 - Basic Profile</mat-button-toggle>
              </mat-button-toggle-group>
              <div *ngIf="maskingLevel(0)">
                <strong>None</strong>
                <p>No masking is applied and all data is passed to the custom transformation capability.</p>
              </div>
              <div *ngIf="maskingLevel(1)">
                <strong>Mobile</strong>
                <p>Only the mobile number is hidden with a pseudo number that can be passed in exports by referencing a custom field called psuedo_mobile</p>
              </div>
              <div *ngIf="maskingLevel(2)">
                <strong>Email</strong>
                <p>Only the email address is hidden with a pseudo number that can be passed in exports by referencing a custom field called psuedo_email</p>
              </div>
              <div *ngIf="maskingLevel(3)">
                <strong>Mobile &amp; Email</strong>
                <p>Both the mobile number and the email address is hidden with a pseudo number and email address that can be passed in exports by referencing custom fields called psuedo_mobile and psuedo_email</p>
              </div>
              <div *ngIf="maskingLevel(4)">
                <strong>Basic Profile</strong>
                <p>
                  All the following information is hidden and can be referenced with the named custom field:
                </p>
                <ul>
                  <li>Title - psuedo_title</li>
                  <li>First Name - psuedo_firstname</li>
                  <li>Last Name - psuedo_lastname</li>
                  <li>Mobile Number - psuedo_mobile</li>
                  <li>Email Address - psuedo_email</li>
                </ul>
              </div>
              <div>
                <mat-checkbox class="example-margin" formControlName="CustomCustom">Supply Custom Data</mat-checkbox>
                <p>Choose whether or not to supply any custom data imported along with the standard data imported. Sometimes it can help assess what transformations need to take place.</p>
              </div>
              <div>
                <button mat-raised-button color="primary" (click)="testCustomUrl()">Test Custom Transformation</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-step>
    <mat-step *ngIf="automate">
      <div [formGroup]="thirdFormGroup">
        <ng-template matStepLabel>When should you {{isExport ? 'export' : 'import'}} it?</ng-template>
        <p>
          Set up a schedule for this automated {{isExport ? 'export' : 'import'}}.
        </p>
        <app-task-scheduler [schedule]="schedule" (scheduleChange)="scheduleChanged($event)"></app-task-scheduler>
      </div>
    </mat-step>
  </mat-stepper>
  <ng-container *ngTemplateOutlet="editorButtons"></ng-container>
</div>
<div *ngIf="preview == 2">
  <div class="simple-row">
    <div class="comm-edtr-divider-div">
      <mat-divider></mat-divider>
    </div>
    <div>
      <button mat-mini-fab color="accent" matTooltip="Edit Definition" (click)="editDefinition(1)"><mat-icon>edit</mat-icon></button>
    </div>
    <div *ngIf="isQuotas()">
      <button mat-mini-fab color="accent" matTooltip="View Quotas" (click)="editDefinition(7)"><mat-icon>track_changes</mat-icon></button>
    </div>
    <div *ngIf="process?.ProcessId > 0">
      <button mat-mini-fab color="accent" matTooltip="Refresh" (click)="editDefinition(8)"><mat-icon>refresh</mat-icon></button>
    </div>
    <div>
      <button mat-mini-fab matTooltip="Cancel" (click)="cancel()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="comm-edtr-divider-div">
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="mt-10">
    <div>
      <div><strong>{{process.Name}}</strong></div>
      <div><span class="sub-title">({{process.ProcessId}})&nbsp;</span></div>
      <div>Modified: {{process.Amended | date:'shortDate': '' : translate.currentLang}} {{process.Amended | date:'mediumTime': '' : translate.currentLang}}</div>
      <div>{{errors.length > 0 ? errors.length + (process.StatusDescription == "Idle" ? " Previous" : "") + " Errors" : "There are no errors"}}</div>
    </div>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Messages">
        <div class="margin-10">
          <div *ngIf="messages.length == 0">
            <div>
              There is no activity recorded for this definition
            </div>
          </div>
          <div *ngFor="let error of messages; let i = index;" class="fieldRowWithPadding">
            <div>
              <mat-icon>check_circle</mat-icon>
            </div>
            <div>
              {{error.Created | date:'short': '' : translate.currentLang}}&nbsp;&nbsp;
            </div>
            <div>
              {{errorMessageDate(error.Message) == null ? '' : 'From ' + (errorMessageDate(error.Message) | date:'short': '' : translate.currentLang) + ', '}}
              {{errorMessageValue(error.Message)}}
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Errors">
        <div class="margin-10">
          <div *ngIf="errors.length == 0">
            <div>
              There is no activity recorded for this definition
            </div>
          </div>
          <div *ngFor="let error of errors; let i = index;" class="fieldRowWithPadding">
            <div>
              <mat-icon color="warn">warning</mat-icon>
            </div>
            <div>
              {{error.Created | date:'short': '' : translate.currentLang}}&nbsp;&nbsp;
            </div>
            <div *ngIf="errors[i].TypeId == 0">
              {{error.Line}}:{{errors[i].Col}}&nbsp;&nbsp;
            </div>
            <div *ngIf="isAdminUser">
              <pre>
                {{getErrorMessage(error.Message)}}
              </pre>
            </div>
            <div *ngIf="!isAdminUser">
              {{getErrorMessage(error.Message)}}
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<div *ngIf="preview == 7">
  <div class="simple-row">
    <div class="comm-edtr-divider-div">
      <mat-divider></mat-divider>
    </div>
    <div>
      <button mat-mini-fab color="accent" matTooltip="Edit Definition" (click)="editDefinition(1)"><mat-icon>edit</mat-icon></button>
    </div>
    <div>
      <button mat-mini-fab color="accent" matTooltip="View History" (click)="editDefinition(2)"><mat-icon>history</mat-icon></button>
    </div>
    <div *ngIf="process?.ProcessId > 0">
      <button mat-mini-fab color="accent" matTooltip="Refresh" (click)="editDefinition(8)"><mat-icon>refresh</mat-icon></button>
    </div>
    <div>
      <button mat-mini-fab color="accent" matTooltip="Cancel" (click)="cancel()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="comm-edtr-divider-div">
      <mat-divider></mat-divider>
    </div>
  </div>
  <div class="mt-10">
    <div>
      <div><strong>{{process.Name}}</strong></div>
      <div><span class="sub-title">({{process.ProcessId}})&nbsp;</span></div>
      <div>Modified: {{process.Amended | date:'shortDate': '' : translate.currentLang}} {{process.Amended | date:'mediumTime': '' : translate.currentLang}}</div>
      <div>{{errors.length > 0 ? errors.length + (process.StatusDescription == "Idle" ? " Previous" : "") + " Errors" : "There are no errors"}}</div>
    </div>
    <div class="fullWidth">
      <h3>Import Quotas</h3>
      <p>
        Control the number of recipients imported into a panel and what happens when those quotas are reached.
        <strong>Current number of recipients: {{quotaTotal < 1 ? 0 : quotaTotal}}</strong>
      </p>
      <div class="column-flex">
        <div style="border: 1px solid #ccc;border-radius:5px">
          <app-quota-definitions [quotas]="quotas" [quotaTotal]="quotaTotal" [variables]="variables"></app-quota-definitions>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #editorButtons>
  <div class="simple-row">
    <div class="comm-edtr-divider-div">
      <mat-divider>
      </mat-divider>
    </div>
    <div>
      <button mat-mini-fab matTooltip="Save" (click)="saveProcess()"><mat-icon>save</mat-icon></button>
    </div><div>
      <button *ngIf="!automate && isExport" mat-mini-fab matTooltip="Export" (click)="exportData()"><mat-icon>download</mat-icon></button>
    </div><div>
      <button *ngIf="!automate && !isExport" mat-mini-fab matTooltip="Import" (click)="importData()"><mat-icon>upload</mat-icon></button>
    </div><div>
      <button *ngIf="automate" mat-mini-fab matTooltip="Test" (click)="isExport ? exportData() : importData()"><mat-icon>bug_report</mat-icon></button>
    </div><div>
      <button *ngIf="automate" mat-mini-fab matTooltip="Launch" (click)="launchProcess()"><mat-icon>rocket_launch</mat-icon></button>
    </div>
    <div>
      <button mat-mini-fab color="accent" matTooltip="View History" (click)="editDefinition(2)"><mat-icon>history</mat-icon></button>
    </div>
    <div *ngIf="isQuotas()">
      <button mat-mini-fab color="accent" matTooltip="View Quotas" (click)="editDefinition(7)"><mat-icon>track_changes</mat-icon></button>
    </div>
    <div *ngIf="process?.ProcessId > 0">
      <button mat-mini-fab color="accent" matTooltip="Refresh" (click)="editDefinition(8)"><mat-icon>refresh</mat-icon></button>
    </div>
    <div>
      <button mat-mini-fab matTooltip="Close" (click)="cancel()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="comm-edtr-divider-div">
      <mat-divider>
      </mat-divider>
    </div>
  </div>
</ng-template>
<ng-template #tester>
  <div class="margin-20">
    <app-file-process [process]="tempProcess" [showErrors]="true" (completed)="onExportDataCompleted($event)"></app-file-process>
  </div>
</ng-template>
