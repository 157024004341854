import { Injectable, Input, Component, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { baseUrl, options } from "../environments/environment";
import { Observable, Subject, Subscription } from 'rxjs';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { plainToClass } from 'class-transformer';
import { ChartData } from './casedata.Service';
import { colorSets } from './charts/color-sets.service';
import { SurveyModel } from './survey.Service';
import { AnswerModel } from './lib/models/AnswerModel';
import { LegendPosition } from '@swimlane/ngx-charts';
import { multi, single } from './data.service';
import { JsonCyclic } from './utils';
import { UserFeatures } from './user.Service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService {

  invokeAddNewComponentFunction = new EventEmitter();
  subsVar?: Subscription;

  subject = new Subject<any>();

  constructor() { }

  onAddComponentButtonClick(data: any) {
    this.invokeAddNewComponentFunction.emit(data);
  }

  sendClickEvent(data: any) {
    this.subject.next(data);
  }

  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
}

@Injectable()
export class InterviewService {
  constructor(private http: HttpClient) {
  }

  setForPreview(model: InterviewModel): Observable<any> {
    return this.http.post(baseUrl + 'Interview/SetForPreview', JsonCyclic.toJson(model), options);
  }

  requestPreview(model: string, parent: number, question: number): Observable<any> {
    return this.http.post<any>(baseUrl + 'Interview/Request?parent=' + parent + '&question=' + question, model, options);
  }

  analyzeInterview(model: string): Observable<LanguageAnalysis> {
    return this.http.patch<LanguageAnalysis>(baseUrl + 'Interview/AnalyzeInterview', model, options);
  }

  getAllLanguages(): Observable<LanguageDefinition[]> {
    return this.http.get<LanguageDefinition[]>(baseUrl + 'Interview/GetAllLanguages', options);
  }

  getLanguages(model: string): Observable<any> {
    return this.http.patch(baseUrl + 'Interview/ExportLanguages', model, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
  }

  setLanguages(filename: string, model: string): Observable<any> {
    return this.http.post(baseUrl + "Interview/ImportLanguages?filename=" + filename, model, options);
  }

  interviewSetup(isReview: boolean, id: string, interviewModel: InterviewModel, params: ParamModel[]): Observable<any> {
    let model = { Model: interviewModel, Params: params };
    let json = JsonCyclic.toJson(model);
    return this.http.post<any>(baseUrl + 'Interview/InterviewSetup?isReview=' + isReview + "&id=" + id, json, options);
  }

  getScript(model: InterviewModel): Observable<any> {
    let json = JsonCyclic.toJson(model);
    return this.http.post<any>(baseUrl + 'Interview/GetScript', json, options);
  }

  getScriptFile(surveyId: number): Observable<any> {
    return this.http.get(baseUrl + 'Interview/GetBySurveyId?id=' + surveyId, {
      responseType: "blob",
    });
  }

  getScriptPlainText(model: InterviewModel): Observable<any> {
    let json = JsonCyclic.toJson(model);
    return this.http.post<any>(baseUrl + 'Interview/GetScriptPlainText', json, options);
  }

  downloadErrorBundle(model: InterviewModel): Observable<any> {
    let json = JsonCyclic.toJson(model);
    return this.http.post(baseUrl + 'Interview/DownloadErrorBundle', json, {
      responseType: "blob",
      headers: new HttpHeaders().append("Content-Type", "application/json")
    });
  }

  interviewAction(sessionToken: string, action: number, answers: AnswerModel[]) {
    return this.http.post<any>(baseUrl + 'Interview/InterviewAction?sessionToken=' + sessionToken + "&action=" + action, answers, options);
  }

  changeLanguage(sessionToken: string, language: string) {
    let answers = [];
    answers.push(new AnswerModel("Language", language));
    return this.http.patch(baseUrl + 'Interview/ChangeLanguage?sessionToken=' + sessionToken, answers, options)
  }

  interviewContinue(sessionToken: string) {
    return this.http.get<any>(baseUrl + 'Interview/InterviewContinue?sessionToken=' + sessionToken, options);
  }

  createEmptyInterview(id: number): Observable<any> {
    return this.http.get(baseUrl + 'Interview/CreateEmptyInterview?id=' + id, options);
  }

  getInterview(id: number): Observable<InterviewModel> {
    return this.http.get<InterviewModel>(baseUrl + 'Interview/Get?id=' + id, options);
  }

  saveInterview(id: string, model: string): Observable<any> {
    return this.http.post(baseUrl + "Interview/Post?id=" + id, model, options);
  }

  saveInterviewFromFile(id: string, filename: string): Observable<any> {
    return this.http.get(baseUrl + "Interview/SaveInterviewFromFile?id=" + id + "&filename=" + filename, options);
  }

  getLibrary(): Observable<any> {
    return this.http.get(baseUrl + "Interview/GetLibrary", options);
  }

  addToLibrary(question: QuestionModel): Observable<any> {
    return this.http.post(baseUrl + "Interview/AddToLibrary", JsonCyclic.toJson(question), options);
  }

  deleteFromLibrary(id: number): Observable<any> {
    return this.http.patch(baseUrl + "Interview/DeleteFromLibrary?id=" + id, options);
  }
}

export class ParamModel {
  Name: string;
  Value: string;

  constructor(name: string, value: string) {
    this.Name = name;
    this.Value = value;
  }
}

export class LanguageAnalysis {
  Summary: LanguageSummary[] = [];
  InterviewModel: InterviewModel;
  IsComplete: boolean = false;
}

export class LanguageSummary {
  Language: LanguageDefinition = new LanguageDefinition();
  Visible: boolean = false;
  Elements: LanguageElement[] = [];
}

export class LanguageResult {
  Name: string;
  Valid: boolean;
}

export class LanguageElement {
  Reference: string;
  QuestionFullName: string;
  AdditionalElement: string;
  LanguageElementType: number;
  Valid: boolean;
}

export class LanguageDefinition {
  Region: string = "";
  Code: string = "";
  Description: string = "";
  Locale: string = "";
}

export class LibraryQuestionModel {
  QuestionLibraryID: number = 0;
  UserID: number = 0;
  QuestionXML: string = "";
  IsDeleted: boolean = false;
  CreatedDate: boolean = false;
  LastUpdatedDate: boolean = false;
}

export class CategoriesModel {
  Order: string = "none";
  List: CategoryModel[] = [];

  AddCategory(name: string, label: string, fixed?: boolean, factory?: string): CategoryModel {
    const category = new CategoryModel(this.List.length, name, label, fixed, factory);
    this.List.push(category);
    return category;
  }
}

export class CategoryMap {
  Entries: CategoryMapEntry[] = [];

  constructor() {
    this.Entries = [];
  }
}

export class CategoryMapEntry {
  Name: string;
  Value: number;

  constructor(name: string, value: number) {
    this.Name = name;
    this.Value = value;
  }
}


export class RuleList {
  Question: QuestionModel | null = null;
  Rules: RuleGroup[];
  Quota: QuotaModel = null;

  selectionTypesConstants = [
    { questionType: "information", ruleType: ["goto", "skip"] },
    { questionType: "mcsa", ruleType: ["goto", "question", "options", "carry", "skip"] },
    { questionType: "mcma", ruleType: ["goto", "question", "options", "carry", "skip"] },
    { questionType: "block", ruleType: ["goto", "skip"] },
    { questionType: "textbox", ruleType: ["goto", "question", "string", "skip"] },
    { questionType: "email", ruleType: ["goto", "question", "string", "skip"] },
    { questionType: "geocode", ruleType: ["goto", "skip"] },
    { questionType: "barcode", ruleType: ["goto", "skip"] },
    { questionType: "boolean", ruleType: ["goto", "question", "boolean", "skip"] },
    { questionType: "integer", ruleType: ["goto", "question", "numeric", "skip"] },
    { questionType: "media", ruleType: ["goto", "skip"] },
    { questionType: "date", ruleType: ["goto", "question", "date", "skip"] },
    { questionType: "currency", ruleType: ["goto", "question", "numeric", "skip"] },
    { questionType: "rating", ruleType: ["goto", "question", "numeric", "skip"] },
    { questionType: "matrix", ruleType: ["goto", "skip"] },
    { questionType: "maxDiff", ruleType: ["goto", "skip"] },
    { questionType: "imageMap", ruleType: ["goto", "skip"] },
    { questionType: "bucket", ruleType: ["goto", "skip"] },
    { questionType: "ranking", ruleType: ["goto", "question", "rank", "skip"] },
    { questionType: "sendEmail", ruleType: ["goto", "skip"] },
    { questionType: "userlist", ruleType: [] },
    { questionType: "advRouting", ruleType: ["goto", "skip"] },
    { questionType: "sendSMS", ruleType: ["goto", "skip"] },
    { questionType: "terminate", ruleType: ["goto", "skip"] }
  ];

  conditionListConstants = [
    { conditionType: "boolean", conditions: [{ id: "true", value: "Yes" }, { id: "false", value: "No" }] },
    {
      conditionType: "numeric", conditions:
        [{ id: "eq", value: "Equal To" }, { id: "ne", value: "Not Equal To" }, { id: "gt", value: "Greater Than" }, { id: "lt", value: "Less Than" },
        { id: "ge", value: "Greater Than or Equal To" }, { id: "le", value: "Less Than or Equal To" }]
    },
    {
      conditionType: "string", conditions:
        [{ id: "eq", value: "Equal To" }, { id: "ne", value: "Not Equal To" }, { id: "ct", value: "Contains" }, { id: "nc", value: "Does Not Contain" },
        { id: "sw", value: "Starts With" }, { id: "ew", value: "Ends With" }]
    },
    {
      conditionType: "date", conditions:
        [{ id: "eq", value: "Equal To" }, { id: "ne", value: "Not Equal To" }, { id: "gt", value: "Greater Than" }, { id: "lt", value: "Less Than" },
        { id: "ge", value: "Greater Than or Equal To" }, { id: "le", value: "Less Than or Equal To" }, { id: "se", value: "Has Been Set" }, { id: "sx", value: "Has Not Been Set" }]
    },
    {
      conditionType: "options", conditions:
        [{ id: "is", value: "Is Selected" }, { id: "ns", value: "Is Not Selected" }]
    },
    {
      conditionType: "object", conditions:
        [{ id: "eq", value: "Equal To" }, { id: "ne", value: "Not Equal To" }, { id: "gt", value: "Greater Than" }, { id: "lt", value: "Less Than" },
        { id: "ge", value: "Greater Than or Equal To" }, { id: "le", value: "Less Than or Equal To" },
        { id: "is", value: "Is Selected" }, { id: "ns", value: "Is Not Selected" }]
    },
{
      conditionType: "rank", conditions:
        [{ id: "eq", value: "Is Set At" }, { id: "gt", value: "Higher Than" }, { id: "ge", value: "Higher Than or Equal To" },
        { id: "lt", value: "Lower Than" }, { id: "le", value: "Lower Than or Equal To" }]
    },
    {
      conditionType: "carry", conditions:
        [{ id: "all", value: "All Choices" }, { id: "dis", value: "All that are Displayed" }, { id: "nds", value: "All that are not Displayed" },
        { id: "sel", value: "All that are Selected" }, { id: "nsl", value: "All that are not Selected" }]
    },
    {
      conditionType: "skip", conditions:
        [{ id: "is", value: "Is Selected" }, { id: "ns", value: "Is Not Selected" }]
    }
  ];

  errorTypeConstants = [
    { errorType: "Attempts", description: "Exceed Attempts" },
    { errorType: "Any", description: "Any Error" },
    { errorType: "NotInRange", description: "Not In Range" },
    { errorType: "TooManyDigits", description: "Too Many Digits" },
    { errorType: "NotNumeric", description: "Not Numeric" },
    { errorType: "NotDate", description: "Not a Date" },
    { errorType: "TooFewAnswers", description: "Too Few Answers" },
    { errorType: "TooManyDecimalPlaces", description: "Too Many Decimal Places" },
    { errorType: "NotSingleAnswer", description: "Not a Single Answer" },
    { errorType: "TooManyAnswers", description: "Too Many Answers" },
    { errorType: "CannotCombine", description: "Cannot Combine" },
    { errorType: "MissingAnswer", description: "Missing Answer" },
    { errorType: "OtherNotSelected", description: "Other Not Selected" },
    { errorType: "InvalidText", description: "Invalid Text" },
    { errorType: "QuestionTooMuchText", description: "Too Much Text" },
    { errorType: "QuestionTooLittleText", description: "Too Little Text" }
  ];

  errorTypeListConstants = [
    { questionType: "mcsa", errorTypes: ["Attempts", "Any", "TooManyAnswers", "MissingAnswer", "OtherNotSelected"] },
    { questionType: "mcma", errorTypes: ["Attempts", "Any", "TooManyAnswers", "TooFewAnswers", "MissingAnswer", "OtherNotSelected", "CannotCombine"] },
    { questionType: "textbox", errorTypes: ["Attempts", "Any", "InvalidText", "QuestionTooMuchText", "QuestionTooLittleText", "MissingAnswer"] },
    { questionType: "email", errorTypes: ["Attempts", "Any", "InvalidText", "QuestionTooMuchText", "QuestionTooLittleText", "MissingAnswer"] },
    { questionType: "geocode", errorTypes: ["Attempts", "Any", "InvalidText", "QuestionTooMuchText", "QuestionTooLittleText", "MissingAnswer"] },
    { questionType: "barcode", errorTypes: ["Attempts", "Any", "InvalidText", "QuestionTooMuchText", "QuestionTooLittleText", "MissingAnswer"] },
    { questionType: "boolean", errorTypes: ["Attempts", "Any", "InvalidText", "MissingAnswer"] },
    { questionType: "integer", errorTypes: ["Attempts", "Any", "NotInRange", "TooManyDigits", "NotNumeric", "MissingAnswer"] },
    { questionType: "date", errorTypes: ["Attempts", "Any", "NotInRange", "NotDate", "MissingAnswer"] },
    { questionType: "integer", errorTypes: ["Attempts", "Any", "NotInRange", "TooManyDecimalPlaces", "NotNumeric", "MissingAnswer"] },
    { questionType: "rating", errorTypes: ["Attempts", "Any", "NotInRange", "TooManyDigits", "NotNumeric", "MissingAnswer"] },
    { questionType: "mcma", errorTypes: ["Attempts", "Any", "TooManyAnswers", "TooFewAnswers", "MissingAnswer"] },
    { questionType: "matrix", errorTypes: ["Attempts", "Any", "TooManyAnswers", "TooFewAnswers", "MissingAnswer"] }
  ];

  selectionTypes: SelectionType[] = this.selectionTypesConstants;

  conditionTypes: ConditionType[] = this.conditionListConstants;

  errorTypes: ErrorType[] = this.errorTypeConstants;

  errorTypeLists: ErrorTypeList[] = this.errorTypeListConstants;

  questionTypeForSelection: QuestionTypesForSelection = new QuestionTypesForSelection(this.selectionTypes);

  constructor(question?: QuestionModel) {
    if (question) {
      this.Question = question;
    }

    this.Rules = [];
  }

  findSelectionType(question: QuestionModel): SelectionType | null {
    let questionType = question.QuestionType;
    if (question.QuestionType == "advanced") {
      switch (question.Action) {
        case "embed":
          if (question.Questions.List.length > 0) {
            questionType = question.Questions.List[0].QuestionType;
          }

          break;
      }
    }
    for (var i = 0; i < this.selectionTypes.length; i++) {
      if (this.selectionTypes[i].questionType === questionType) {
        return this.selectionTypes[i];
      }
    }
    return null;
  }

  findConditionType(selectionType: SelectionType, withCarry: boolean, withSkip: boolean): ConditionType | null {
    if (selectionType === undefined) {
      return null;
    }

    for (let i = 0; i < this.conditionTypes.length; i++) {
      for (let j = 0; j < selectionType.ruleType.length; j++) {
        if (selectionType.ruleType[j] === this.conditionTypes[i].conditionType) {
          if (withCarry) {
            if (selectionType.ruleType[j] !== "options") {
              return this.conditionTypes[i];
            }
          }
          else {
            if (withSkip) {
              if (selectionType.ruleType[j] !== "options" && selectionType.ruleType[j] !== "carry") {
                return this.conditionTypes[i];
              }
            }
            else {
              return this.conditionTypes[i];
            }
          }
        }
      }
    }

    return null;
  }

  findConditionName(conditionType: ConditionType, id: string): string {
    for (let i = 0; i < conditionType.conditions.length; i++) {
      if (conditionType.conditions[i].id === id) {
        return conditionType.conditions[i].value;
      }
    }

    return "";
  }

  findErrorName(errorType: string): string {
    for (let i = 0; i < this.errorTypes.length; i++) {
      if (errorType == this.errorTypes[i].errorType) {
        return this.errorTypes[i].description;
      }
    }

    return "";
  }

  buildSummary(name: string): string {
    if (name == undefined) {
      return "";
    }

    if (this.Rules == undefined) {
      return "";
    }

    if (this.Rules.length === 0) {
      return "";
    }

    if (this.Rules[0].Rules.length === 0) {
      return "";
    }

    let summary = "";
    if (name === "Branch ") {
      let q1 = this.Question?.Interview.findQuestion(this.Rules[0].TargetId);
      if (q1 != null) {
        summary = "to " + q1.FullName + " ";
      }
    }
    else {
      summary = name;
    }

    for (let i = 0; i < this.Rules[0].Rules.length; i++) {
      let rule = this.Rules[0].Rules[i];
      if (i === 0) {
        if (rule.RuleType === "skip") {
          summary += "to";
        }
        else {
          if (rule.RuleType !== "carry" && rule.RuleType !== "error" && name != "When ") {
            summary += "if";
          }
        }
      }
      else {
        if (rule.RuleType === "skip") {
          summary += " or skip to";
        }
        else if (rule.RuleType === "error") {
          summary += " or"
        }
        else {
          if (rule.Link === null) {
            continue;
          }

          if (rule.Link == "") {
            summary += ", ";
          }
          else {
            summary += " " + rule.Link;
          }
        }
      }

      let q;
      let c;
      let st;

      switch (rule.RuleType) {
        case "goto":
          q = this.Question?.Interview?.findQuestion(this.Rules[0].TargetId);
          if (q != null) {
            summary = " to " + q.FullName;
          }

          break;
        case "question":
        case "embed":
          q = this.Question?.Interview?.findQuestion(rule.QuestionId);
          if (q != null) {
            summary += " " + q.FullName;
            if (rule.QuestionElement != "") {
              summary += "." + rule.QuestionElement;
            }

            st = this.findSelectionType(q);
            if (st == null) {
              throw "Selection Type not found";
            }

            if (rule.QuestionElement == null) {
              rule.QuestionElement = "Response";
            }

            if (rule.QuestionElement != "" && rule.QuestionElement != "Response") {
              for (let i2 = 0; i2 < this.conditionTypes.length; i2++) {
                if (this.conditionTypes[i2].conditionType == "date") {
                  c = this.conditionTypes[i2];
                  break;
                }
              }
            }
            else {
              c = this.findConditionType(st, false, false);
            }

            if (c == null) {
              return summary;
            }

            if (c.conditionType === "options") {
              summary += " [" + q.getOptions(rule.Answers).join(', ') + "]";
              summary += " " + this.findConditionName(c, rule.Condition);
            }
            else if (c.conditionType === "rank") {
              if (rule.Answers !== undefined) {
                summary += " [" + q.getOptions([rule.Answers[0]]) + "]";
              }
              summary += " " + this.findConditionName(c, rule.Condition);
              if (rule.Answers !== undefined) {
                summary += " " + rule.Answers[1];
              }
            }
            else {
              summary += " " + this.findConditionName(c, rule.Condition);
              if (rule.Condition != "se" && rule.Condition != 'sx') {
                summary += " " + rule.Answers.join(',');
              }
            }
          }

          break;
        case "carry":
          q = this.Question?.Interview?.findQuestion(rule.QuestionId);
          if (q) {
            st = this.findSelectionType(q);
            if (st == null) {
              throw "Selection Type not found";
            }

            c = this.findConditionType(st, true, false);
            if (c == null) {
              return summary;
            }

            summary += " " + q.FullName;
            summary += " " + this.findConditionName(c, rule.Condition);
          }

          break;
        case "skip":
          q = this.Question;
          if (q) {
            summary += " " + q.FullName + " if";
            st = this.findSelectionType(q);
            if (st == null) {
              throw "Selection Type not found";
            }

            c = this.findConditionType(st, false, true);
            if (c == null) {
              return summary;
            }

            if (q.Categories.List && q.Categories.List.length > 0) {
              if (rule.Answers) {
                summary += " [" + q.getOptions(rule.Answers).join(', ') + "]";
              }
              summary += " " + this.findConditionName(c, rule.Condition);
            }
            else {
              summary += " " + this.findConditionName(c, rule.Condition);
              if (rule.Answers) {
                summary += " " + rule.Answers.join(',');
              }
            }
          }

          break;
        case "profile":
          summary += rule.QuestionId;
          for (let i = 0; i < this.conditionTypes.length; i++) {
            if (this.conditionTypes[i].conditionType == "object") {
              c = this.conditionTypes[i];
              break;
            }
          }

          summary += " is " + this.findConditionName(c, rule.Condition);
          if (rule.Answers) {
            summary += " " + rule.Answers.join(',');
          }

          break;
        case "error":
          q = this.Question?.Interview?.findQuestion(rule.QuestionId);
          if (q) {
            if (rule.Condition == "Attempts") {
              summary += ", when attempts exceed "
              if (rule.Answers && rule.Answers.length > 0 && rule.Answers[0].trim() != "") {
                summary += rule.Answers[0];
                summary += " goto " + q.FullName;
              }
            }
            else {
              summary += ", when '" + this.findErrorName(rule.Condition) + "'"
              if (rule.Answers && rule.Answers.length > 0 && rule.Answers[0].trim() != "") {
                summary += " display error '" + rule.Answers[0] + "'";
                summary += " and goto " + q.FullName;
              }
              else {
                summary += " goto " + q.FullName;
              }
            }
          }

          break;
      }

    }

    if (name === "When ") {
      summary += " apply target of " + (this.Quota?.Target == -1 ? "Undefined" : this.Quota?.Target);
      let q1 = this.Question?.Interview.findQuestion(this.Rules[0].TargetId);
      if (q1 != null) {
        summary += " goto " + q1.FullName + " ";
      }
      else {
        switch (this.Quota?.Action) {
          case 0:
            summary += " do not add recipient";
            break;
          case 1:
            summary += " continue to process";
            break;
          case 2:
            summary += " spread across the reset period";
            break;
        }
      }

      if (this.Quota?.Reset) {
        summary += ". Reset every " + (this.Quota.Value == -1 ? '0' : this.Quota.Value);
        switch (this.Quota.Unit) {
          case 0:
            summary += " day(s)";
            break;
          case 1:
            summary += " month(s)";
            break;
        }

        if (this.Quota?.Starting) {
          try {
            summary += " starting on " + new Date(this.Quota?.Starting).toDateString();
          }
          catch {
            summary += " starting on Undefined";
          }
        }
      }
    }

    return summary;
  }
}

export class SelectionType {
  questionType: string = "";
  ruleType: string[] = [];
}

export class QuestionTypesForSelection {
  questionTypesForSelection: QuestionTypeForSelection[] = [];

  constructor(selectionTypes: SelectionType[]) {
    for (let i = 0; i < selectionTypes.length; i++) {
      for (let j = 0; j < selectionTypes[i].ruleType.length; j++) {
        this.addSelectionAndQuestionType(selectionTypes[i].ruleType[j], selectionTypes[i].questionType);
      }
    }
  }

  findQuestionTypesForSelection(selectionType: string): string[] {
    if (selectionType === "embed") {
      return ["embed"];
    }

    for (let i = 0; i < this.questionTypesForSelection.length; i++) {
      if (this.questionTypesForSelection[i].selectionType == selectionType) {
        return this.questionTypesForSelection[i].questionTypes;
      }
    }

    return [];
  }

  addSelectionAndQuestionType(selectionType: string, questionType: string) {
    let index = -1;
    for (let i = 0; i < this.questionTypesForSelection.length; i++) {
      if (this.questionTypesForSelection[i].selectionType == selectionType) {
        this.questionTypesForSelection[i].addQuestionType(questionType);
        index = i;
        break;
      }
    }

    if (index > -1) {
      return;
    }

    let qtfs = new QuestionTypeForSelection();
    qtfs.selectionType = selectionType;
    qtfs.addQuestionType(questionType);
    this.questionTypesForSelection.push(qtfs);
  }
}

export class QuestionTypeForSelection {
  selectionType: string = "";
  questionTypes: string[] = [];

  addQuestionType(questionType: string) {
    if (this.questionTypes.includes(questionType)) {
      return;
    }

    this.questionTypes.push(questionType);
  }
}

export class ConditionType {
  conditionType: string = "";
  conditions: Condition[] = [];
}

export class Condition {
  id: string = "";
  value: string = "";
}

export class ErrorTypeList {
  questionType: string = "";
  errorTypes: string[] = [];
}

export class ErrorType {
  errorType: string = "";
  description: string = "";
}

export class ReportInterviewModel {
  OffsetTime: number;
  Report?: ReportModel;
  Interview?: InterviewModel;

  constructor(report?: ReportModel, interview?: InterviewModel) {
    this.OffsetTime = new Date().getTimezoneOffset();
    this.Report = report;
    this.Interview = interview;
  }
}

export class RuleGroup {
  Link: string = "";
  Rules: RuleModel[] = [];
  TargetId: string = ""; // The question id of the target
}

export class RuleModel {
  Link: string = ""; // or, and
  RuleType: string = ""; // question, 
  QuestionId: string = "";
  QuestionElement: string = "";
  Answers: string[] = []; // Could be more than one answer in the list
  Condition: string = ""; // 'eq' = equal, 'ne' = not equal, etc.
}

export class QuotaModel {
  Target: number = -1;
  Progress: number = -1;
  Action: number = -1;
  Reset: boolean = false;
  Value: number = -1;
  Unit: number = -1;
  Starting: Date = new Date();
  QuotaId: number = -1;
}

export class QuestionsModel {
  Order: number = 0;
  List: QuestionModel[] = [];
}

export class EditorState {
  Editor: boolean = false;
  Order: boolean = false;
  Properties: boolean = false;
  BasicProperties: boolean = false;
  AdvancedProperties: boolean = false;
  Preview: boolean = false;
  QuestionId: string = "";
  Error: boolean = false;
}

export class ReportModel {
  State: EditorState = new EditorState();
  TestData: boolean = false;
  Title: string = "";
  Description: string = "";
  Visualization: string = "";
  Questions: ReportingQuestionModel[] = [];

  constructor(survey?: SurveyModel, interview?: InterviewModel) {
    if (survey) {
      this.Title = survey.Name;
      this.Description = survey.Description;
    }

    if (interview) {
      this.buildQuestions(interview);
    }

    this.State = new EditorState();
  }

  buildQuestions(interview: InterviewModel) {
    if (this.Questions.length > 0) {
      return;
    }

    let questions = interview.reportableQuestions();
    for (let i = 0; i < questions.length; i++) {
      this.Questions.push(new ReportingQuestionModel(questions[i]));
      switch (questions[i].QuestionType) {
        case "mcma":
        case "mcsa":
          for (let j = 0; j < questions[i].Categories.List.length; j++) {
            if (questions[i].Categories.List[j].Other != null) {
              let other = questions[i].Categories.List[j].Other;
              this.Questions.push(new ReportingQuestionModel(other));
            }
          }

          break;
      }
    }
  }

  findQuestion(id: string): ReportingQuestionModel | null {
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].QuestionId === id) {
        return this.Questions[i];
      }
    }

    return null;
  }

  checkModel(survey: SurveyModel, interview: InterviewModel) {
    if (this.Title === null || this.Title === "") {
      this.Title = survey.Name;
    }

    this.buildQuestions(interview);

    // Now check the interview model
    for (let i = 0; i < interview.Questions.length; i++) {
      let temp = null;
      switch (interview.Questions[i].QuestionType) {
        case "advanced":
          break;
        case "block":
          for (let j = 0; j < interview.Questions[i].Questions.List.length; j++) {
            temp = this.findQuestion(interview.Questions[i].Questions.List[j].QuestionId);
            if (temp == null) {
              this.Questions.push(new ReportingQuestionModel(interview.Questions[i].Questions.List[j]));
            }
          }

          break;
        case "mcma":
        case "mcsa":
          temp = this.findQuestion(interview.Questions[i].QuestionId);
          if (temp === null) {
            this.Questions.push(new ReportingQuestionModel(interview.Questions[i]));
          }

          for (let j = 0; j < interview.Questions[i].Categories.List.length; j++) {
            if (interview.Questions[i].Categories.List[j].Other != null) {
              temp = this.findQuestion(interview.Questions[i].Categories.List[j].Other.QuestionId);
              if (temp == null) {
                let other = interview.Questions[i].Categories.List[j].Other;
                this.Questions.push(new ReportingQuestionModel(other));
              }
            }
          }

          break;
        default:
          temp = this.findQuestion(interview.Questions[i].QuestionId);
          if (temp == null) {
            this.Questions.push(new ReportingQuestionModel(interview.Questions[i]));
          }

          break;
      }
    }
  }
}

export class ReportingQuestionModel {
  Selected: boolean = false;
  QuestionId: string = "";
  Title: string = "";
  ReportType: string = "";
  ColourScheme: string = "";

  constructor(question?: QuestionModel) {
    if (question) {
      this.QuestionId = question.QuestionId;
      this.Title = question.FullName + " " + question.FormattedBanner;
    }
  }
}

export class StyleModel {
  Name: string = "";
  Logo: number = 0;
  FooterLogoIsDark: boolean = false;
  BackgroundColor: string = "";
  HeaderColor: string = "";
  NavigationColor: string = "";
  ControlsColor: string = "";
  ErrorHandling: number = 1;

  constructor(name?: string, logo?: number, footerLogoIsDark?: boolean) {
    if (name) {
      this.Name = name;
    }

    if (logo) {
      this.Logo = logo;
    }

    if (footerLogoIsDark) {
      this.FooterLogoIsDark = footerLogoIsDark;
    }
  }
}

export class InterviewModel {
  Guid: string = "";
  Questions: QuestionModel[] = [];
  State: EditorState;
  Map: CategoryMap;
  Style: StyleModel;
  Channel: string = "web";
  Languages: string[] = [];
  ShowLanguage: boolean[] = [];
  DefaultLanguage: string = "";
  AllowLanguageChoice: boolean = false;
  CustomLanguages: LanguageDefinition[] = [];
  IsInvite: boolean = false;

  constructor() {
    this.Questions = [];
    this.State = new EditorState();
    this.Map = new CategoryMap();
    this.Style = new StyleModel();
  }

  static idCounter: number = 0;
  static get nextId(): string {
    return (InterviewModel.idCounter++).toString();
  }

  idMap: Map<string, string> = new Map<string, string>();

  getId(questionId: string): string {
    if (this.idMap.has(questionId)) {
      let newId = questionId;
      while (this.idMap.has(newId)) {
        newId = InterviewModel.nextId;
      }

      this.idMap.set(newId, questionId);
      return newId;
    }

    this.idMap.set(questionId, questionId);
    return questionId;
  }

  getUrlPanelQuestions(): QuestionModel[] {
    let temp: QuestionModel[] = [];
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].QuestionType == "advanced" && this.Questions[i].Action == "embed") {
        for (let j = 0; j < this.Questions[i].Questions.List.length; j++) {
          if (this.Questions[i].Questions.List[j].Action == "url") {
            let found = false;
            for (let k = 0; k < temp.length; k++) {
              if (this.Questions[i].Questions.List[j].QuestionName == temp[k].QuestionName) {
                found = true;
                break;
              }
            }

            if (!found) {
              temp.push(this.Questions[i].Questions.List[j]);
            }
          }
        }
      }
    }

    return temp;
  }

  getDisplayQuestions(): QuestionModel[] {
    let temp = [];
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].QuestionType != "advanced") {
        temp.push(this.Questions[i]);
        if (this.Questions[i].QuestionType === "block") {
          for (let j = 0; j < this.Questions[i].Questions.List.length; j++) {
            temp.push(this.Questions[i].Questions.List[j]);
          }
        }
      }
    }

    return temp;
  }

  getRoutingQuestion(): QuestionModel[] {
    let temp = [];
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].QuestionType == "advanced") {
        if (this.Questions[i].Action != "navigation") {
          temp.push(this.Questions[i]);
        }
      }
      else {
        temp.push(this.Questions[i]);
      }
    }

    return temp;
  }

  findNavigation(): QuestionModel | null {
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].QuestionType == "advanced" && this.Questions[i].Action == "navigation") {
        return this.Questions[i];
      }
    }

    return null;
  }

  findQuestionByIndex(parentIndex: number, index: number): QuestionModel {
    if (parentIndex > -1) {
      return this.Questions[parentIndex].Questions.List[index];
    }
    else {
      return this.Questions[index];
    }
  }

  findQuestionByDisplayIndex(parentIndex: number, index: number): QuestionModel | null {
    if (parentIndex > -1) {
      for (let i = 0; i < this.Questions.length; i++) {
        if (this.Questions[i].DisplayIndex == parentIndex) {
          let question = this.Questions[i]
          for (let j = 0; j < question.Questions.List.length; j++) {
            if (question.Questions.List[j].DisplayIndex == index) {
              return question.Questions.List[j];
            }
          }
        }
      }

      return null;
    }

    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].DisplayIndex == index) {
        return this.Questions[i];
      }
    }

    return null;
  }

  getSummary() {
    let pages = 0;
    let questions = 0;
    let responses = 0;
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].QuestionType != "advanced") {
        questions++;
      }

      if (this.reportableQuestion(this.Questions[i])) {
        responses++;
      }

      for (let j = 0; j < this.Questions[i].Questions.List.length; j++) {
        questions++;
        if (this.reportableQuestion(this.Questions[i].Questions.List[j])) {
          responses++;
        }
      }

      pages++;
    }

    if (questions === 0) {
      return "There are no questions setup";
    }

    let summary = "There are " + questions + " question" + (questions === 1 ? "" : "s");
    if (questions !== pages) {
      summary += " over " + pages + " page" + (pages === 1 ? "" : "s");
    }

    if (questions !== responses) {
      summary += " with " + responses + " question" + (responses === 1 ? "" : "s") + " requiring a response";
    }

    return summary;
  }

  checkModel() {
    let a = -1;
    for (let i = 0; i < this.Questions.length; i++) {
      const question = plainToClass(QuestionModel, this.Questions[i]);
      question.QuestionId = this.getId(question.QuestionId);
      question.ParentIndex = -1;
      question.Index = i;
      question.Interview = this;
      if (question.QuestionType !== "advanced") {
        a += 1;
      }

      question.DisplayParentIndex = -1;
      question.DisplayIndex = a;
      question.checkModel();
      this.Questions[i] = question;
      let b = -1;
      for (let j = 0; j < this.Questions[i].Questions.List.length; j++) {
        const subQuestion = plainToClass(QuestionModel, this.Questions[i].Questions.List[j]);
        subQuestion.QuestionId = this.getId(subQuestion.QuestionId);
        subQuestion.ParentIndex = i;
        subQuestion.Index = j
        subQuestion.Interview = this;
        if (question.QuestionType !== "advanced") {
          b += 1;
        }

        subQuestion.DisplayParentIndex = a;
        subQuestion.DisplayIndex = b;
        subQuestion.checkModel();
        this.Questions[i].Questions.List[j] = subQuestion;
      }
    }
  }

  newQuestion(data: any): QuestionModel {
    if (data.parentIndex !== undefined && data.parentIndex >= 0) {
      return this.insertQuestion(this.Questions[data.parentIndex].Questions.List, data);
    }

    return this.insertQuestion(this.Questions, data);
  }

  deleteQuestion(data: any) {
    if (data.parentIndex !== undefined && data.parentIndex >= 0) {
      this.Questions[data.parentIndex].Questions.List.splice(data.index, 1);
      this.reIndex(data.parentIndex, data.displayParentIndex, this.Questions[data.parentIndex].Questions.List);
      return;
    }

    this.Questions.splice(data.index, 1);
    this.reIndex(data.parentIndex, data.displayParentIndex, this.Questions);
  }

  findQuestion(questionId: string): QuestionModel | null {
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].QuestionId === questionId) {
        return this.Questions[i];
      }

      for (let j = 0; j < this.Questions[i].Questions.List.length; j++) {
        if (this.Questions[i].Questions.List[j].QuestionId === questionId) {
          return this.Questions[i].Questions.List[j];
        }
      }

      switch (this.Questions[i].QuestionType) {
        case "mcma":
        case "mcsa":
          for (let j = 0; j < this.Questions[i].Categories.List.length; j++) {
            if (this.Questions[i].Categories.List[j].Other != null && this.Questions[i].Categories.List[j].Other.QuestionId == questionId) {
              return this.Questions[i].Categories.List[j].Other;
            }
          }

          break;
        case "advanced":
          if (this.Questions[i].Action == "embed") {
            for (let j = 0; j < this.Questions[i].Questions.List.length; j++) {
              if (this.Questions[i].Questions.List[j].QuestionId == questionId) {
                return this.Questions[i].Questions.List[j];
              }
            }
          }
      }
    }

    return null;
  }

  findQuestionByFullname(fullname: string): QuestionModel | null {
    let parts = fullname.split(".");
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].QuestionName == parts[0]) {
        if (parts.length == 1) {
          return this.Questions[i];
        }

        for (let j = 0; j < this.Questions[i].Questions.List.length; j++) {
          if (this.Questions[i].Questions.List[j].QuestionName == parts[1]) {
            return this.Questions[i].Questions.List[j];
          }
        }
      }
    }

    return null;
  }

  findQuestionWithData(data: any): QuestionModel {
    if (data.parentIndex !== undefined && data.parentIndex > -1) {
      return this.Questions[data.parentIndex].Questions.List[data.index];
    }

    return this.Questions[data.index];
  }

  copyQuestion(data: any) {
    let question = this.findQuestionWithData(data);
    let json = JsonCyclic.toJson(question);
    let newQuestion = plainToClass(QuestionModel, JSON.parse(json));
    newQuestion.QuestionId = this.getId(InterviewModel.nextId);
    newQuestion.Interview = this;
    for (let j = 0; j < newQuestion.Questions.List.length; j++) {
      const subQuestion = plainToClass(QuestionModel, newQuestion.Questions.List[j]);
      subQuestion.ParentIndex = data.index + 1;
      subQuestion.Index = j
      subQuestion.Interview = this;
      subQuestion.checkModel();
      newQuestion.Questions.List[j] = subQuestion;
    }

    return newQuestion;
  }

  moveQuestionOutOfBlock(previousParentIndex: number, previousIndex: number, targetParentIndex: number, targetIndex: number) {
    // Remove from the current position
    let questions = this.Questions;
    if (previousParentIndex > -1) {
      questions = this.Questions[previousParentIndex].Questions.List;
    }

    let question = questions[previousIndex];
    questions.splice(previousIndex, 1);

    // Display Indexes for reindexing
    let previousDisplayParentIndex = question.DisplayParentIndex;

    // Could have shifted everything before we can insert so just check it out
    if (previousParentIndex === -1) {
      if (previousIndex < targetParentIndex) {
        targetParentIndex--;
      }
    }

    // Add into the new position
    questions = this.Questions;
    let targetDisplayParentIndex = -1;
    if (targetParentIndex > -1) {
      questions = this.Questions[targetParentIndex].Questions.List;
      targetDisplayParentIndex = this.Questions[targetParentIndex].DisplayIndex;
    }

    if (targetIndex === questions.length) {
      questions.push(question);
    }
    else {
      questions.splice(targetIndex, 0, question);
    }

    this.reIndex(targetParentIndex, targetDisplayParentIndex, questions)

    if (targetParentIndex == -1) {
      if (previousParentIndex > targetParentIndex) {
        previousParentIndex++;
        previousDisplayParentIndex++;
      }
    }

    questions = this.Questions[previousParentIndex].Questions.List;
    if (questions.length > 0) {
      this.reIndex(previousParentIndex, previousDisplayParentIndex, questions)
    }
  }

  reportableQuestions(): QuestionModel[] {
    let questions: QuestionModel[] = [];
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].QuestionType === "block") {
        for (let j = 0; j < this.Questions[i].Questions.List.length; j++) {
          if (this.reportableQuestion(this.Questions[i].Questions.List[j])) {
            questions.push(this.Questions[i].Questions.List[j]);
          }
        }
      }
      else {
        if (this.reportableQuestion(this.Questions[i])) {
          questions.push(this.Questions[i]);
        }
      }
    }

    return questions;
  }

  textQuestions(): QuestionModel[] {
    let questions: QuestionModel[] = [];
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].QuestionType === "block") {
        for (let j = 0; j < this.Questions[i].Questions.List.length; j++) {
          if (this.textQuestion(this.Questions[i].Questions.List[j])) {
            questions.push(this.Questions[i].Questions.List[j]);
          }
        }
      }
      else {
        if (this.textQuestion(this.Questions[i])) {
          questions.push(this.Questions[i]);
        }
      }
    }

    return questions;
  }

  dataQuestions(): QuestionModel[] {
    let questions: QuestionModel[] = [];
    for (let i = 0; i < this.Questions.length; i++) {
      if (this.Questions[i].QuestionType === "block") {
        for (let j = 0; j < this.Questions[i].Questions.List.length; j++) {
          if (this.dataQuestion(this.Questions[i].Questions.List[j])) {
            questions.push(this.Questions[i].Questions.List[j]);
          }
        }
      }
      else {
        if (this.dataQuestion(this.Questions[i])) {
          questions.push(this.Questions[i]);
        }
      }
    }

    return questions;
  }

  dataQuestion(question: QuestionModel): boolean {
    switch (question.QuestionType) {
      case 'media':
      case 'imageMap':
      case 'sendEmail':
      case 'userlist':
      case 'advRouting':
      case 'sendSMS':
      case 'block':
        return false;
      case 'information':
      case 'terminate':
      case 'mcsa':
      case 'mcma':
      case 'textbox':
      case 'email':
      case 'geocode':
      case 'barcode':
      case 'boolean':
      case 'integer':
      case 'date':
      case 'currency':
      case 'rating':
      case 'matrix':
      case 'maxDiff':
      case 'bucket':
      case 'ranking':
        return true;
      case 'advanced':
        return question.Action == "embed";
    }

    return false;
  }

  textQuestion(question: QuestionModel): boolean {
    switch (question.QuestionType) {
      case 'block':
      case 'media':
      case 'imageMap':
      case 'sendEmail':
      case 'userlist':
      case 'advRouting':
      case 'sendSMS':
      case 'terminate':
      case 'advanced':
      case 'information':
      case 'mcsa':
      case 'mcma':
      case 'geocode':
      case 'barcode':
      case 'boolean':
      case 'integer':
      case 'date':
      case 'currency':
      case 'rating':
      case 'matrix':
      case 'maxDiff':
      case 'bucket':
      case 'ranking':
        return false;
      case 'textbox':
      case 'email':
        return true;
    }

    return false;
  }

  reportableQuestion(question: QuestionModel): boolean {
    switch (question.QuestionType) {      
      case 'media':
      case 'imageMap':
      case 'sendEmail':
      case 'userlist':
      case 'advRouting':
      case 'sendSMS':
      case 'advanced':    
        return false;
      case 'block':
      case 'terminate':
      case 'information':
      case 'mcsa':
      case 'mcma':
      case 'textbox':
      case 'email':
      case 'geocode':
      case 'barcode':
      case 'boolean':
      case 'integer':
      case 'date':
      case 'currency':
      case 'rating':
      case 'matrix':
      case 'maxDiff':
      case 'bucket':
      case 'ranking':
        return true;
    }

    return false;
  }

  questionList(subQuestions: boolean, questionTypes: string[], listType: string): QuestionModel[] {
    return this.buildQuestionList(this.Questions, subQuestions, questionTypes, listType);
  }

  private buildQuestionList(questions: QuestionModel[], subQuestions: boolean, questionTypes: string[], listType: string): QuestionModel[] {
    const list = [];
    for (let i = 0; i < questions.length; i++) {
      let question = questions[i];
      let questionType = question.QuestionType;
      if (question.QuestionType === "advanced") {
        if (question.Action == "embed") {
          questionType = question.Action;
        }
      }

      if (questionTypes.length === 0 || questionTypes.includes(questionType) || questionType == "block") {
        switch (questionType) {
          case "block":
            let subList = this.buildQuestionList(question.Questions.List, subQuestions, questionTypes, listType);
            for (let j = 0; j < subList.length; j++) {
              list.push(subList[j]);
            }

            break;
          case "randomise":
            if (listType !== "randomise") {
              list.push(question);
            }

            break;
          case "embed":
            if (listType != "branch") {
              for (let j = 0; j < question.Questions.List.length; j++) {
                let found = false;
                for (let k = 0; k < list.length; k++) {
                  if (question.Questions.List[j].QuestionName == list[k].QuestionName) {
                    found = true;
                    break;
                  }
                }

                if (!found) {
                  list.push(question.Questions.List[j]);
                }
              }
            }

            break;
          case "advanced":
            break;
          default:
            list.push(question);
            break;
        }
      }
      else {
        if (subQuestions && listType !== "branch") {
          for (let j = 0; j < question.Questions.List.length; j++) {
            let q = question.Questions.List[j];
            if (questionTypes.length === 0 || questionTypes.includes(questionType)) {
              list.push(q);
            }
            else {
              if (questionTypes.length === 1 && questionTypes[0] === "embed") {
                if (questionType === "embed") {
                  list.push(q);
                }
              }
            }
          }
        }
      }
    }

    return list;
  }

  insertQuestion(questions: QuestionModel[], data: any): QuestionModel {
    const newQuestion = data.action === "copy" ? this.copyQuestion(data) : new QuestionModel(this, data);
    if (data.index === -1) {
      questions.push(newQuestion);
      this.reIndex(data.parentIndex, data.displayParentIndex, questions);
      return newQuestion;
    }

    if (data.action === "before") {
      questions.splice(data.index, 0, newQuestion);
      this.reIndex(data.parentIndex, data.displayParentIndex, questions);
      return newQuestion;
    }

    if ((data.index + 1) > questions.length) {
      questions.push(newQuestion);
      this.reIndex(data.parentIndex, data.displayParentIndex, questions);
      return newQuestion;
    }

    questions.splice(data.index + 1, 0, newQuestion);
    this.reIndex(data.parentIndex, data.displayParentIndex, questions);
    return newQuestion;
  }

  reIndex(parentIndex: number, displayParentIndex: number, questions: QuestionModel[]) {
    if (parentIndex === undefined || parentIndex === null) {
      parentIndex = -1;
    }

    if (displayParentIndex === undefined || displayParentIndex === null) {
      displayParentIndex = -1;
    }

    let index = 0;
    let displayIndex = -1;
    for (let i = 0; i < questions.length; i++) {
      let model = questions[i];
      if (model.QuestionType !== "advanced") {
        displayIndex += 1;
      }

      // Clear the name, if it right to do so, so that it gets overwritten
      if (!this.compareQuestionName(model, displayIndex)) {
        model.QuestionName = "";
      }

      model.DisplayParentIndex = displayParentIndex;
      model.ParentIndex = parentIndex;
      model.Index = index++;
      model.DisplayIndex = displayIndex;
      this.nameQuestion(model, displayIndex);
      if (model.QuestionType == 'block') {
        this.reIndex(model.Index, model.DisplayIndex, model.Questions.List);
      }
    }
  }

  private getQuestionName(model: QuestionModel, displayIndex: number): string {
    let index = displayIndex + 1;
    let name = "";
    switch (model.QuestionType) {
      case "block":
        name = "B" + (index);
        break;
      case "userlist":
        name = "U" + (index);
        break;
      case "advanced":
        switch (model.Action) {
          case "randomise":
            name = "R" + (index);
            break;
          default:
            name = "A";
            break;
        }
        break;
      default:
        name = "Q" + (index);
        break;
    }

    return name;
  }

  private compareQuestionName(model: QuestionModel, displayIndex: number): boolean {
    let name = this.getQuestionName(model, model.DisplayIndex);
    if (model.QuestionName != null && model.QuestionName != undefined) {
      if (model.QuestionName.trim() == "") {
        return false;
      }

      if (model.QuestionName.trim().toLowerCase() != name.trim().toLowerCase()) {
        return true;
      }
    }

    return false;
  }

  private nameQuestion(model: QuestionModel, displayIndex: number) {
    model.QuestionNameTouched = this.compareQuestionName(model, displayIndex);
    if (!model.QuestionNameTouched) {
      model.QuestionName = this.getQuestionName(model, displayIndex);
    }
  }
}

@Injectable()
export class ParameterModel {
  Name: string = "";
  Value: string = "";
  LanguageValue: LabelModel[] = [];
}

export class LabelModel {
  Language: string = "";
  Label: string = "";
}

export class QuestionModel {
  QuestionId: string = InterviewModel.nextId; // Internal Id
  Index: number = -1;
  DisplayIndex: number = -1;
  QuestionName: string = "";
  QuestionNameTouched: boolean = false;
  Fix: boolean = false;
  Min: number = -1;
  Max: number = -1;
  Step: number = -1;
  Precision: number = -1;
  Placeholder: string = "";
  Mask: string = "";
  Media: number = -1;
  MediaLink: string = "";
  Control: string = "";
  Orientation: string = "";
  LabelType: string = "";
  LowLabel: string = "";
  LowLanguageLabel: LabelModel[] = [];
  HighLabel: string = "";
  HighLanguageLabel: LabelModel[] = [];
  Banner: string = "";
  LanguageBanner: LabelModel[] = [];
  Categories: CategoriesModel = new CategoriesModel();
  MustAnswer: boolean = false;
  QuestionType: string = "textbox";
  Parameters: ParameterModel[] = [];
  InitialValue: string = "";
  DefaultValue: string = "";
  Questions: QuestionsModel = new QuestionsModel();
  Interview: InterviewModel = new InterviewModel();
  ParentIndex: number = -1;
  DisplayParentIndex: number = -1;
  Rules: RuleList[] = [];
  DisplayLogic: RuleList | null = null;
  AnswerChoiceLogic: RuleList | null = null;
  CarryForward: RuleList | null = null;
  SkipLogic: RuleList | null = null;
  ErrorLogic: RuleList | null = null;
  Action: string = "";
  NotApplicable: boolean = false;

  constructor(interview?: InterviewModel, data?: any) {
    if (interview) {
      this.Interview = interview;
      this.QuestionId = this.Interview.getId(this.QuestionId);
    }
    if (data) {
      this.QuestionType = data.questionType;
      this.Index = data.index;
      this.ParentIndex = data.parentIndex;
      switch (this.QuestionType) {
        case "date":
          this.Control = "datetime";
          break;
        case "rating":
          if (this.Interview.Channel == "sms") {
            this.Control = "none";
          }
          else {
            this.Control = "slider";
          }

          this.Min = 1;
          this.LowLabel = "Min";
          this.Max = 5;
          this.HighLabel = "Max";
          break;
        case "mcma":
          this.AddDummyCategories();
          switch (this.Interview.Channel) {
            case "whatsapp":
              this.Control = "button";
              break;
            default:
              this.Control = "checkboxes";
              break;
          }

          break;
        case "mcsa":
          this.AddDummyCategories();
          switch (this.Interview.Channel) {
            case "whatsapp":
              this.Control = "button";
              break;
            default:
              this.Control = "radiobutton";
              break;
          }

          break;
        case "ranking":
          this.Categories.AddCategory("a", "Rank 1");
          this.Categories.AddCategory("b", "Rank 2");
          break;
        case "boolean":
          this.Categories.AddCategory("true", "Yes");
          this.Categories.AddCategory("false", "No");
          this.Control = "radiobutton";
          break;
      }
    }
    else {
      this.QuestionType = "unknown";
      this.Index = -1;
      this.ParentIndex = -1;
    }
  }

  private AddDummyCategories() {
    this.Categories.AddCategory("a", "Option 1");
    this.Categories.AddCategory("b", "Option 2");
  }

  findParameter(name: string): ParameterModel {
    for (let i = 0; i < this.Parameters.length; i++) {
      if (this.Parameters[i].Name == name) {
        return this.Parameters[i];
      }
    }

    return null;
  }

  get QuestionTypeDescription(): string {
    switch (this.QuestionType) {
      case "information":
        return "Survey Introduction";
      case "textbox":
        return "Free Text";
      case "email":
        return "Email Address";
      case "mcma":
        return "Multiple Answer";
      case "mcsa":
        return "Single Answer";
      case "matrix":
        return "Matrix";
      case "rating":
        return "Rating";
      case "ranking":
        return "Rank Order";
      case "currency":
        return "Currency";
      case "integer":
        return "Number";
      case "boolean":
        return "Yes or No";
      case "date":
        return "Date & Time";
      case "terminate":
        return "Close Survey";
      case "block":
        return "Block";
      case "maxDiff":
        return "MaxDiff";
      case "bucket":
        return "Grouping (Card Sort)";
      case "imageMap":
        return "Image Map";
      case "media":
        return "Media";
      case "geocode":
        return "Location";
      case "barcode":
        return "Barcode";
      case "sendSMS":
        return "Send SMS";
      case "sendEmail":
        return "Send Email";
      case "userlist":
        return "User List";
    }

    return "";
  }

  get FullName(): string {
    if (this.ParentIndex === undefined || this.ParentIndex === null || this.ParentIndex == -1) {
      if (this.QuestionType == "advanced" && this.Questions.List.length > 0) {
        return this.Questions.List[0].QuestionName;
      }

      return this.QuestionName;
    }

    if (this.Interview.Questions[this.ParentIndex].QuestionType == "advanced") {
      return this.QuestionName;
    }

    return this.Interview.Questions[this.ParentIndex].QuestionName + "." + this.QuestionName;
  }

  get FormattedBanner(): string {
    if (this.Banner === undefined || this.Banner === null || this.Banner === "") {
      return "";
    }

    return this.Banner.replace(/<\/?[^>]+(>|$)/g, "").replace("&nbsp;", " ").replace("&amp;", "&");
  }

  get FormatBannerForSms(): string {
    let banner = this.FormattedBanner;
    switch (this.QuestionType) {
      case "mcma":
      case "mcsa":
      case "ranking":
        switch (this.QuestionType) {
          case "mcma":
            banner += ". Choose any of the following options: ";
            break;
          case "mcsa":
            banner += ". Choose one of the following options: ";
            break;
          case "ranking":
            banner += ". Rank the following options in order of preference: ";
            break;
        }

        for (let i = 0; i < this.Categories.List.length; i++) {
          if (i + 1 == this.Categories.List.length) {
            banner += " and "
          }

          banner += this.Categories.List[i].Name + " " + this.Categories.List[i].Label;
          if (i + 1 == this.Categories.List.length) {
            banner += "."
          }
          else {
            banner += ", "
          }
        }
    }

    return banner;
  }

  get ShortName(): string {
    if (this.QuestionType === "advanced") {
      return this.QuestionName;
    }

    return this.QuestionName;
  }

  get Charts(): string[] {
    switch (this.QuestionType) {
      case 'information':
      case 'sendEmail':
      case 'userlist':
      case 'advRouting':
      case 'sendSMS':
      case 'terminate':
      case 'advanced':
      case 'block':
        return [];
      case 'mcsa':
      case 'mcma':
        return ["vertical", "horizontal", "pie"];
      case 'boolean':
        return ["vertical", "horizontal", "table"];
      case 'rating':
        return ["vertical", "horizontal","table" ,"pie"];
      case 'maxDiff':
        return ["vertical", "horizontal"];
      case 'bucket':
        return ["groupedvertical", "groupedhorizontal"];
      case 'textbox':
        return ["table", "card", "cloud"];
      case 'email':
      case 'barcode':
      case 'integer':
        return ["table"];
      case 'date':
        return ["table"];
      case 'currency':
        return ["table"];
      case 'geocode':
        return ["table", "map"];
      case 'media':
        return ["media"];
      case 'matrix':
        if (this.Control) {
          switch (this.Control) {
            case "checkbox":
            case "radiobutton":
              return ["groupedvertical", "groupedhorizontal", "line","table"];
            case "textbox":
              return ["tableMatrix", "card"];
          }
        }

        return ["groupedvertical", "groupedhorizontal", "line"];
      case 'imageMap':
        return ["table", "imagemap"];
      case 'ranking':
        return ["stackedvertical", "stackedhorizontal"];
    }

    return [];
  }

  get Icon(): string {
    switch (this.QuestionType) {
      case 'information':
        return 'info';
      case 'mcsa':
        return "radio_button_checked";
      case 'mcma':
        return "check_box";
      case 'block':
        return 'article';
      case 'textbox':
        return "text_fields";
      case 'email':
        return 'email';
      case 'geocode':
        return 'location_on';
      case 'barcode':
        return 'qr_code_scanner'
      case 'boolean':
        return 'thumbs_up_down';
      case 'integer':
        return 'looks_one';
      case 'media':
        return 'camera';
      case 'date':
        return 'date_range';
      case 'currency':
        return 'money';
      case 'rating':
        return "star_rate";
      case 'matrix':
        return 'apps';
      case 'maxDiff':
        return 'insert_chart_outlined';
      case 'imageMap':
        return 'image';
      case 'bucket':
        return 'group_work';
      case 'ranking':
        return 'reorder';
      case 'sendEmail':
        return 'send';
      case 'userlist':
        return 'radio_button_checked';
      case 'advRouting':
        break;
      case 'sendSMS':
        return 'sms';
      case 'terminate':
        return 'stop_circle';
      case 'advanced':
        switch (this.Action) {
          case "embed":
            return "note_add";
          case "randomise":
            return "shuffle_on";
          case "web":
            return "cloud";
          case "route":
            return "account_tree";
          case "quota":
            return "track_changes";
        }
        return "alt_route";
    }

    return "help_center";
  }

  get FullSummary(): string {
    return this.buildSummary(true);
  }

  get Summary(): string {
    return this.buildSummary(false);
  }

  private buildSummary(fullname: boolean): string {
    let summary = "";

    let variables = []
    switch (this.QuestionType) {
      case "textbox":
        summary = "[" + (fullname ? this.FullName : this.QuestionName) + "] " + this.FormattedBanner;
        break;
      case "advanced":
        switch (this.Action) {
          case "quota":
            if (this.Rules?.length > 0) {
              summary += this.Rules[0].buildSummary("When ");
            }
            else {
              summary += "Quota undefined";
            }

            break;
          case "route":
            if (this.Rules?.length > 0) {
              summary += "Branch " + this.Rules[0].buildSummary("Branch ");
            }
            else {
              summary += "Branch undefined";
            }

            break;
          case "embed":
            summary += "Embedded ";
            for (let i = 0; i < this.Questions.List.length; i++) {
              const question = this.Questions.List[i];
              let temp = question.QuestionName == "" ? "Unknown" : question.QuestionName;
              switch (question.Action) {
                case "url":
                  temp += " from Url/Panel";
                  break;
                case "meta":
                  temp += " (Survey Metadata)";
                  break;
                case "value":
                  temp += " set to " + question.InitialValue;
                  break;
                case "question":
                  let source = "Unknown";
                  if (question.DefaultValue != "") {
                    let sq = question.Interview.findQuestion(question.DefaultValue);
                    if (sq != null) {
                      source = sq.FullName;
                      if (question.InitialValue != "") {
                        source += "." + question.InitialValue;
                      }
                    }
                  }

                  temp += " from question " + source;
                  break;
              }

              variables.push(temp);
            }
            summary += variables.join(", ");
            break;
          case "randomise":
            summary += "[" + (fullname ? this.FullName : this.QuestionName) + "] Randomise " + this.InitialValue + " of ";
            for (let i = 0; i < this.Questions.List.length; i++) {
              if (this.Questions.List[i].QuestionName !== "") {
                variables.push(this.Questions.List[i].QuestionName)
              }
            }
            summary += variables.join(",");
            break;
        }
        break;
      default:
        summary = "[" + (fullname ? this.FullName : this.QuestionName) + "] " + this.FormattedBanner;
        break;
    }

    return summary
  }

  getOptions(answers: string[]): string[] {
    let result = [];
    if (answers == null) {
      return result;
    }

    for (let i = 0; i < answers.length; i++) {
      for (let j = 0; j < this.Categories.List.length; j++) {
        if (this.Categories.List[j].Name === answers[i]) {
          result.push(this.Categories.List[j].Label);
        }
      }
    }

    return result;
  }

  addRuleList(): RuleList {
    let list = new RuleList(this);
    this.Rules.push(list);
    return list;
  }

  checkAllModel() {
    this.checkModel();
    for (let i = 0; i < this.Questions.List.length; i++) {
      const question = plainToClass(QuestionModel, this.Questions.List[i]);
      question.checkModel();
      this.Questions.List[i] = question;
    }
  }

  checkModel() {
    switch (this.QuestionType) {
      case "mcma":
      case "mcsa":
        for (let i = 0; i < this.Categories.List.length; i++) {
          if (this.Categories.List[i].Other != null) {
            const question = plainToClass(QuestionModel, this.Categories.List[i].Other);
            question.QuestionName = this.FullName + "." + this.Categories.List[i].Name + ".Other";
            // this.Banner = this.FormatBannerForSms + ".Other";
            question.QuestionType = "textbox";
            question.checkModel();
            this.Categories.List[i].Other = question;
          }
        }

        break;
    }

    for (let i = 0; i < this.Rules.length; i++) {
      const rule = plainToClass(RuleList, this.Rules[i]);
      rule.Question = this;
      this.Rules[i] = rule;
    }

    this.DisplayLogic = plainToClass(RuleList, this.DisplayLogic);
    if (this.DisplayLogic && this.DisplayLogic.Question == null) {
      this.DisplayLogic.Question = this;
    }

    this.CarryForward = plainToClass(RuleList, this.CarryForward);
    if (this.CarryForward && this.CarryForward.Question == null) {
      this.CarryForward.Question = this;
    }

    this.AnswerChoiceLogic = plainToClass(RuleList, this.AnswerChoiceLogic);
    if (this.AnswerChoiceLogic && this.AnswerChoiceLogic.Question == null) {
      this.AnswerChoiceLogic.Question = this;
    }

    this.SkipLogic = plainToClass(RuleList, this.SkipLogic);
    if (this.SkipLogic && this.SkipLogic.Question!== null) {
      this.SkipLogic.Question = this;
    }

    this.ErrorLogic = plainToClass(RuleList, this.ErrorLogic);
    if (this.ErrorLogic && this.ErrorLogic.Question == null) {
      this.ErrorLogic.Question = this;
    }
  }
}

export class CategoryModel {
  Index: number;
  Name: string;
  Label: string;
  LanguageLabel: LabelModel[] = [];
  TargetId: string = ""; // Question Id of the target is there is a match.
  Media: number = -1;
  Fixed: boolean = false;
  Factor: string;
  Exclusive: boolean = false;
  Other: QuestionModel | null = null;

  constructor(index: number, name: string, label: string, fixed?: boolean, factor?: string) {
    this.Index = index;
    this.Name = name;
    this.Label = label;
    if (fixed) {
      this.Fixed = fixed;
    }
    else {
      this.Fixed = false;
    }

    this.Factor = factor ?? "";
  }

}

export class DataModel {
  questionType: string = "";
  index: number = 0;
  parentIndex: number = 0;
  action: string = "";

  constructor(questionType: string) {
    this.questionType = questionType;
  }
}

export class GroupReportData {
  name: string = "";
  series: any[] = [];
  constructor(name?: string) {
    if (name) {
      this.name = name;
    }
  }
}

export class ReportData {
  name: string;
  value: number;
  date: Date = new Date();
  constructor(name: string, value: number, date?: Date) {
    this.name = name;
    this.value = value;
    this.date = date ?? new Date();
  }
}

export class ReportDataMatrix {
  name: string;
  series: SeriesMatrix[] = [];

  constructor(name: string) {
    this.name = name;
  }
}

export class SeriesMatrix {
  name: string;
  value: number;

  constructor(name: string, value: number) {
    this.name = name;
    this.value = value;
  }
}

@Component({
  template: ''
})
export abstract class BaseChartMulti {

  multi: any[] = [];
  colorSets: any;
  schemeType: string = 'linear';
  legend = false;
  legendTitle = '';
  legendPosition = LegendPosition.Below;
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = '';

  colorScheme: any = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  view: [number, number] = [700, 400];

  constructor() {
    Object.assign(this, { multi, colorSets });
  }

  @Input()
  get scheme() {
    return this.colorSets;
  }
  set scheme(value: any) {
    this.colorSets = value;
  }

  @Input()
  get question(): QuestionModel {
    return this._question;
  }
  set question(value: QuestionModel) {
    this._question = value;
  }
  private _question: QuestionModel = new QuestionModel();

  @Input()
  get results(): ReportModel[] {
    return this._results;
  }
  set results(value: ReportModel[]) {
    this._results = value;
  }
  private _results: ReportModel[] = [];

  @Input()
  get data(): ChartData[] {
    return this._data;
  }
  set data(value: ChartData[]) {
    this._data = value;
  }
  private _data: ChartData[] = [];

  @Input()
  get groupResults(): GroupReportData[] {
    return this._groupResults;
  }
  set groupResults(value: GroupReportData[]) {
    this._groupResults = value;
  }
  private _groupResults: GroupReportData[] = [];
}

@Component({
  template: ''
})
export abstract class BaseChart {

  single: any[] = [];
  colorSets: any;
  legend = false;
  legendTitle = '';
  legendPosition = LegendPosition.Below;
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = '';

  colorScheme: any = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  view: [number, number] = [700, 400];

  constructor() {
    Object.assign(this, { single, colorSets });
  }

  @Input()
  get scheme() {
    return this.colorSets;
  }
  set scheme(value: any) {
    this.colorSets = value;
  }

  @Input()
  get question(): QuestionModel {
    return this._question;
  }
  set question(value: QuestionModel) {
    this._question = value;
  }
  private _question: QuestionModel = new QuestionModel();

  @Input()
  get results(): ReportModel[] {
    return this._results;
  }
  set results(value: ReportModel[]) {
    this._results = value;
  }
  private _results: ReportModel[] = [];

  @Input()
  get data(): ChartData[] {
    return this._data;
  }
  set data(value: ChartData[]) {
    this._data = value;
  }
  private _data: ChartData[] = [];

  @Input()
  get words(): ChartData[] {
    return this._words;
  }
  set words(value: ChartData[]) {
    this._words = value;
  }
  private _words: ChartData[] = [];

}

@Component({
  template: ''
})
export abstract class BaseQuestion {

  formChangeSubscribed: boolean = false;
  formSubScription: Subscription;

  private questionService?: QuestionService;
  private interviewService?: InterviewService;

  constructor(questionService?: QuestionService, interviewService?: InterviewService, interview?: InterviewModel, model?: QuestionModel, data?: DataModel) {
    this.questionService = questionService;
    this.interviewService = interviewService;
    this._preview = true;
    this.show = false;
    this.fb = new FormBuilder();
    if (interview) {
      this.interview = interview;
    }
    if (model) {
      this._model = model;
    }
    else {
      this._model = new QuestionModel(this.interview, data);
    }
  }

  fb: FormBuilder;

  initQuestion() {
    this.formGroup = this.fb.group({
      qNumber: [{ value: this.model.QuestionName, disabled: !this.userFeatures.QuestionEditorFeatures.EditQuestionName }, Validators.required],
      banner: [this.model.Banner]
    });
    return this.formGroup;
  }

  updateQuestionNumber() {
    let control = this.formGroup.controls.qNumber;
    if (control == undefined) {
      let a = 0;
    }
    else {
      control.setValue(this.model.QuestionName);
    }
  }

  clickSmart(value: string) {
    switch (value) {
      case "smart":
        let form = this.questionArr?.controls[0] as FormGroup;
        this.model.Banner = this.model.FormatBannerForSms;
        form.controls.banner.setValue(this.model.Banner);
        break;
    }
  }

  get formGroup(): FormGroup {
    return this._formGroup;
  }
  set formGroup(value: FormGroup) {
    this._formGroup = value;
    if (this._formGroup) {
      if (!this.formChangeSubscribed) {
        this.formSubScription = this._formGroup.valueChanges.subscribe(x => {
          if (this.mainForm.controls.qNumber.valid && this.model.QuestionName !== this.mainForm.controls["qNumber"].value) {
            this.model.QuestionName = this.mainForm.controls["qNumber"].value;
            this.model.QuestionNameTouched = true;
          }

          this.model.Banner = this.mainForm.controls["banner"].value;
          let label = new LabelModel();
          label.Label = this.model.Banner;
          label.Language = this.model.Interview.DefaultLanguage ?? "en-GB";
          if (label.Language == "") {
            label.Language = "en-GB";
          }

          if (this.model.LanguageBanner == null) {
            this.model.LanguageBanner = [];
          }

          if (this.model.LanguageBanner.length == 0) {
            this.model.LanguageBanner.push(label);
          }
          else {
            this.model.LanguageBanner[0] = label;
          }
        });
        this.formChangeSubscribed = true;
      }
    }
  }
  private _formGroup: FormGroup = new FormGroup({});

  @Input()
  get userFeatures(): UserFeatures {
    return this._userFeatures;
  }
  set userFeatures(value: UserFeatures) {
    this._userFeatures = value;
    if (this.mainForm) {
      this.userFeatures.QuestionEditorFeatures.EditQuestionName ? this.mainForm.controls.qNumber.enable() : this.mainForm.controls.qNumber.disable();
    }
  }
  private _userFeatures: UserFeatures = new UserFeatures();

  @Input()
  get interview(): InterviewModel {
    return this._interview;
  }
  set interview(value: InterviewModel) {
    this._interview = value;
    this.loadPreview();
  }
  private _interview: InterviewModel = new InterviewModel();

  @Input()
  get model(): QuestionModel {
    return this._model;
  }
  set model(value: QuestionModel) {
    this._model = value;
    this.loadPreview()
  }
  private _model: QuestionModel;

  customPatterns = { 'S': { pattern: new RegExp('\[a-zA-Z\]') }, 'A': { pattern: new RegExp('\[_0-9a-zA-Z\]') } };

  questionAction(action: string) {
    this.questionService.sendClickEvent(
      {
        questionType: (action == "copy" || action == "delete") ? this.model.QuestionType : "unknown",
        action: action,
        index: this.model.Index,
        parentIndex: this.model.ParentIndex,
        displayIndex: this.model.DisplayIndex,
        displayParentIndex: this.model.DisplayParentIndex,
        channel: this.model.Interview.Channel
      });
  }

  private loadPreview() {
    if (this.model.ParentIndex === -1) {
      let a = 0;
    }
    if (this._model && this._interview && this.model.QuestionType != "block" && this.model.DisplayIndex !== undefined && this.model.DisplayIndex !== null && this.model.DisplayIndex > -1) {
      if (this.interview.Questions.length > 0) {
        this.interviewService?.requestPreview(JsonCyclic.toJson(this.interview), this.model.ParentIndex, this.model.Index).subscribe(
          result => {
            this.previewModel = result;
            this.show = true;
          },
          error => {
            let a = error;
          });
      }
    }
  }

  abstract get questionArr(): FormArray | null;

  stopFormUpdates() {
    if (this.formSubScription) {
      // this.formSubScription.unsubscribe();
    }
  }

  show: boolean;

  @Input()
  get previewModel(): any {
    return this._previewModel;
  }
  set previewModel(value) {
    this._previewModel = value;
  }
  private _previewModel: any;

  @Input()
  get preview(): boolean {
    return this._preview;
  }
  set preview(value: boolean) {
    this._preview = value;
    if (this._preview) {
      this.stopFormUpdates();
    }
  }
  private _preview: boolean;

  get mainForm(): FormGroup {
    return this.questionArr?.controls[0] as FormGroup;
  }

  refresh() {
    if (this.questionArr) {
      let mainArray = this.questionArr.controls[0] as FormGroup;
      mainArray.controls["qNumber"].setValue(this._model.QuestionName);
      this.loadPreview();
    }
  }

  click() {
    if (this.questionService) {
      this.questionService.sendClickEvent(
        {
          questionType: this.model.QuestionType,
          action: "select",
          index: this.model.Index,
          displayIndex: this.model.DisplayIndex,
          parentIndex: this.model.ParentIndex,
          displayParentIndex: this.model.DisplayParentIndex,
          channel: this.model.Interview.Channel
        });
    }
  }
}
