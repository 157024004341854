<div class="twoFA-main-div">
  <div class="twoFA-card">
    <form class="twoFA-forgot-password-form" [formGroup]="formGroup">
      <h2>Two-factor Authentication</h2>
      <div *ngIf="makeSelection">
        <p>
          Choose a method to receive you verification code.
        </p>
        <mat-button-toggle-group #group="matButtonToggleGroup" (click)="checkType()" formControlName="type">
          <mat-button-toggle value="email">Email</mat-button-toggle>
          <mat-button-toggle value="sms">Sms</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div>
        <p><br />When you are ready to authenticate, click the button below</p>
        <button mat-raised-button color="primary" (click)="send()">{{sent ? 'Send Again' : label}}</button>
        <div>
          <br />
          <mat-divider></mat-divider>
          <br />
        </div>
      </div>
      <div *ngIf="sent">
        <p>
          We sent you a verification code via {{ selectionType == 'sms' ? 'Sms' : 'Email'}}. Enter the digits below to proceed.
        </p>
        <div>
        </div>
        <div class="fieldRowWithPadding">
          <app-tfentry #tfentry [(value)]="code" (completed)="onSubmit()"></app-tfentry>
        </div>
        <div class="fieldRowWithPadding">
          <button mat-raised-button submit (click)="onSubmit()" color="primary">Verify</button>
        </div>
      </div>
    </form>
  </div>
</div>
