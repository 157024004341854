<div *ngIf="auditLogEntries.length > 0">
  <div class="fieldRow">
    <div>
      <button mat-mini-fab color="primary" aria-label="Refresh" (clikc)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <div>
    </div>
  </div>
  <div *ngFor="let log of auditLogEntries; let i = index">
    <div style="display:flex;flex-flow:row">
      <div>
        <strong>{{log.Account}} - {{getDescription(log)}}</strong><br />
        {{log.Description}}<br />
        {{log.Country}} | {{log.Created}}
      </div>
      <div>
        <button mat-icon-button color="primary" aria-label="Expand" (click)="expanded[i] = !expanded[i]">
          <mat-icon>{{expanded[i] ? 'arrow_drop_up' : 'arrow_drop_down'}}</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="expanded[i]">
      <div style="display:flex;flex-flow:column;width:100%">
        <div style="display:flex;flex-flow:row;">
          <div style="width:200px">Created:</div>
          <div>{{log.Created}}</div>
        </div>
        <div style="display:flex;flex-flow:row;">
          <div style="width:200px">Audit Log ID:</div>
          <div>{{log.AuditLogId}}</div>
        </div>
        <div style="display:flex;flex-flow:row;">
          <div style="width:200px">Reference:</div>
          <div>({{log.ReferenceId}}) {{log.Description}}</div>
        </div>
        <div style="display:flex;flex-flow:row;">
          <div style="width:200px">Action:</div>
          <div>{{getDescription(log)}}</div>
        </div>
        <div style="display:flex;flex-flow:row;">
          <div style="width:200px">Account:</div>
          <div>({{log.AccountId}}) {{log.Account}}</div>
        </div>
        <div style="display:flex;flex-flow:row;">
          <div style="width:200px">Actor:</div>
          <div>({{log.UserId}}) {{log.Username}}</div>
        </div>
        <div style="display:flex;flex-flow:row;">
          <div style="width:200px">Location:</div>
          <div>({{log.IPAddress}}) {{log.Country}}</div>
        </div>
        <div style="display:flex;flex-flow:row;">
          <div style="width:200px">User Agent:</div>
          <div>{{log.UserAgent}}</div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>

