import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SystemConfig, SystemService } from "../system.Service";
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FindUserComponent } from "../find-user/find-user.component";
import { MatDialog } from "@angular/material/dialog";
import { UserService } from "../user.Service";
import { AuthenticationService } from "../authentication.Service";
import { SurveyService } from '../survey.Service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

interface SettingType {
  id: string;
  text: string;
}

@Component({
  selector: 'app-system-details',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.css']
})
export class SystemComponent implements OnInit {
  settings?: Observable<SystemConfig[]>;
  finalSettings: SystemConfig[] = [];
  formGroup: FormGroup = new FormGroup({});
  selectValue: string = "";

  types: SettingType[] = [
    { id: '', text: 'Undefined' },
    { id: 'System.Int32', text: 'Integer' },
    { id: 'System.Int64', text: 'Large Integer' },
    { id: 'System.Float', text: 'Float' },
    { id: 'System.String', text: 'String' },
    { id: 'System.Date', text: 'Date/Time' },
    { id: 'System.Bool', text: 'Boolean' },
    { id: 'System.Decimal', text: 'Decimal' },
    { id: 'User', text: 'User' }
  ];

  constructor(public dialog: MatDialog,
    private systemService: SystemService,
    private authenticationService: AuthenticationService,
    private surveyService: SurveyService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {
  }

  selectedFile: File | null = null;

  textType(id: string) {
    return this.types.find(x => x.id === id)?.text;
  }

  ngOnInit(): void {
    this.settings =  this.systemService.get().pipe(tap<SystemConfig[]>(settings => {
        let group: any = {};
        settings.forEach(setting => {
            group[setting.ModifiedId.replace('.','')] = new FormControl(setting.Value, Validators.required);
          }
        );
        this.formGroup = new FormGroup(group);
        this.finalSettings = settings;
      })
    );
  }
  
  message() {
    return;
  }

  findUser($event: any, id: any, index: any) {
    $event.preventDefault();
    const dialogRef = this.dialog.open(FindUserComponent, {
      width: '80%',
      data: { id: id }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.formGroup.controls[this.finalSettings[index].ModifiedId.replace('.','')].setValue(result.UserID.toString());
        this.finalSettings[index].UserProfile = result;
      }
    });
  }

  onSignIn($event : any, index: any) {
    $event.preventDefault();
    this.authenticationService.loginAs(this.finalSettings[index].UserProfile.UserName)
      .subscribe(result => {
        const token = (result as any).Token;
        localStorage.setItem("jwt", token);
        this.surveyService.getSurveyCount()
          .subscribe(response => {
            if (response > 0) {
              this.router.navigate(['../surveydashboard']);
            } else {
              this.router.navigate(['../startfromtemplate']);
            }
          },
            error => {
              if (error === 401) {
                this.router.navigate(['../']);
              }
            });
      },
        error => {
          console.error(error);
        });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }

    for (let i = 0; i < this.finalSettings.length; i++) {
      let setting = this.finalSettings[i];
      if (setting.Value != this.formGroup.controls[setting.ModifiedId.replace('.','')].value) {
        setting.Value = this.formGroup.controls[setting.ModifiedId.replace('.','')].value;
        setting.LastUpdatedDate = new Date();
      }
    }
    this.systemService.set(this.finalSettings).subscribe(result => {
      this.openSnackbar("Settings Updated Successfully", "");
    },
      error => {
        this.openSnackbar("There was a problem updating the settings", "Cancel");
      }
    );
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
