import { HttpClient, HttpHeaders } from "@angular/common/http";
import { baseUrl } from "../environments/environment";

export const padEnd = (text: string, no: number, pad: string): string => {
  let returnStr = text
  let num = no - text.length
  while (num > 0) {
    text += pad
  }
  return returnStr
}

export const padStart = (text: string, no: number, pad: string): string => {
  let returnStr = text
  let num = no - text.length
  while (num > 0) {
    text = pad + text
  }
  return returnStr
}

export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key: any, value: any) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export class JsonCyclic {
  public static toJson(value: object): string {
    return JSON.stringify(value, getCircularReplacer());
  }
}

export class ZendeskUtil {

  public static signin(jwt: string, return_to: string) {
    if (baseUrl.startsWith("https://localhost")) {
      window.location.href = return_to;
      return;
    }

    const myform = document.createElement('form');
    myform.method = 'POST';
    var url = baseUrl.replace("/api/", return_to);
    myform.action = 'https://diysurveys.zendesk.com/access/jwt?return_to=' + url;
    myform.style.display = 'none';
    myform.append('Content-Type', 'application/x-www-form-urlencoded');
    myform.append('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8');
    myform.append('Access-Control-Allow-Origin', '*');
    const mapInput = document.createElement('input');
    mapInput.type = 'hidden';
    mapInput.name = 'jwt';
    mapInput.id = 'jwtInput';
    mapInput.value = jwt;
    myform.appendChild(mapInput);
    document.body.appendChild(myform);
    myform.submit();
  } 
}

export class InMemoryCache {
  private cache: Map<string, any>;

  constructor() {
    this.cache = new Map();
  }

  set(key: string, value: any) {
    try {
      localStorage.setItem(key, value);
    }
    catch {
      this.cache.set(key, value);
    }
  }

  get(key: string) {
    try {
      return localStorage.getItem(key);
    }
    catch {
      return this.cache.get(key);
    }
  }

  delete(key: string) {
    try {
      localStorage.removeItem(key)
    }
    catch {
      this.cache.delete(key);
    }
  }

  clear() {
    try {
      localStorage.clear();
    }
    catch {
      this.cache.clear();
    }
  }
}

export class IpAddressUtil {
  static getCurrentIPAddress(): any {
    return new Promise((resolve, reject) => {
      var rtc = new RTCPeerConnection();
      rtc.createDataChannel('');

      rtc.onicecandidate = function (evt) {
        if (evt.candidate) grepSDP("a=" + evt.candidate.candidate);
      };

      rtc.createOffer().then(function (offerDesc) {
        grepSDP(offerDesc.sdp);
        rtc.setLocalDescription(offerDesc);
      },
        function (e) {
          reject(e);
        });

      function grepSDP(sdp) {
        const line = sdp.split('\r\n').find((line) => line.includes("a=candidate"));
        if (line) {
          var parts = line.split(' '),
            addr = parts[4],
            type = parts[7];
          if (type === 'host') {
            const strAddr: string = addr.split('.').join('_');
            resolve(strAddr);
          }
        }
      }
    });
  }
}
