<div style="width:100%">
  <mat-accordion>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">manage_accounts</mat-icon><span class="user-details-expansion-panel-title">Account Activity</span>
          </div>
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>
      <app-active-users></app-active-users>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">rss_feed</mat-icon><span class="user-details-expansion-panel-title">Audit Log</span>
          </div>
        </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>
      <app-auditlog-activity></app-auditlog-activity>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">edit_road</mat-icon><span class="user-details-expansion-panel-title">Gateway Analysis</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-traffic-analysis></app-traffic-analysis>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">traffic_jam</mat-icon><span class="user-details-expansion-panel-title">Traffic Dashboard </span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-traffic-dashboard></app-traffic-dashboard>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">speaker_notes</mat-icon><span class="user-details-expansion-panel-title">Message Insights</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-message-history [system]="true"></app-message-history>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">route</mat-icon><span class="user-details-expansion-panel-title">Background Services</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-service-list [services]="zoneSummary"></app-service-list>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">network_check</mat-icon><span class="user-details-expansion-panel-title">Message Queues</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngFor="let queue of queues; let i = index">
        <div><app-message-queue [queue]="queue" [preview]="i < queuePreviews.length ? queuePreviews[i] : 0" (closed)="processClosed($event)"></app-message-queue></div>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">file_download_off</mat-icon><span class="user-details-expansion-panel-title">Data I/O Checker</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-dataio></app-dataio>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">notifications</mat-icon><span class="user-details-expansion-panel-title">Notifications</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-notification-management></app-notification-management>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">public</mat-icon><span class="user-details-expansion-panel-title">Network Manager</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-manage-network></app-manage-network>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            &nbsp;<i class="bi bi-whatsapp" placement="top"></i>&nbsp;<span class="user-details-expansion-panel-title">WhatsApp Applications</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-manage-whatsapp></app-manage-whatsapp>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">sms</mat-icon><span class="user-details-expansion-panel-title">Send Test Message</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-send></app-send>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">123</mat-icon><span class="user-details-expansion-panel-title">Check Number</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-check-number></app-check-number>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">no_adult_content</mat-icon><span class="user-details-expansion-panel-title">Content Whitelisting</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">groups</mat-icon><span class="user-details-expansion-panel-title">User Groups</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-group-manager></app-group-manager>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">policy</mat-icon><span class="user-details-expansion-panel-title">Policy Manager</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-policy-group-manager></app-policy-group-manager>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">emergency_heat</mat-icon><span class="user-details-expansion-panel-title">Firewall Settings</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-firewall-settings></app-firewall-settings>
    </mat-expansion-panel>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="simple-row">
            <mat-icon class="mini-fab">settings</mat-icon><span class="user-details-expansion-panel-title">System Settings</span>
          </div>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <app-system-details></app-system-details>
    </mat-expansion-panel>
  </mat-accordion>
</div>
