import { Component, Input, OnInit, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { CountryComboComponent } from '../../country-combo/country-combo.component';
import { COMMA, ENTER, L } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { SystemService } from '../../system.Service';

@Component({
  selector: 'app-firewall-settings',
  templateUrl: './firewall-settings.component.html',
  styleUrl: './firewall-settings.component.css'
})
export class FirewallSettingsComponent {
  formGroup: FormGroup;
  countryCtrl = new FormControl();
  filteredCountries: Observable<any[]>;
  countries: any[];
  selectedCountries: any[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('countryInput') countryInput: ElementRef<HTMLInputElement> | undefined;

  constructor(private systemService: SystemService) {
    this.countries = [];
    for (let i = 0; i < CountryComboComponent.locations.length; i++) {
      this.countries.push(CountryComboComponent.locations[i]);
    }

    this.buildForm();
    this.systemService.getByIds(["system:AllowedEmailAddresses",
      "system:countries", "system:ReservedUsernames", "system:blwlcountries", "system:ipaddresses", "system:blwlipaddresses"]).subscribe(result => {
        let values = result[0].split(',');
        let temp = "";
        for (let i = 0; i < values.length; i++) {
          temp += values[i] + '\n';
        }

        this.formGroup.controls.whitelistedUsernames.setValue(temp);
        values = result[2].split(',');
        temp = "";
        for (let i = 0; i < values.length; i++) {
          temp += values[i] + '\n';
        }

        this.formGroup.controls.blacklistedUsernames.setValue(temp);
        values = result[1].split(',');
        for (let i = 0; i < values.length; i++) {
          for (let j = 0; j < this.countries.length; j++) {
            if (this.countries[j].countryCode == values[i]) {
              this.selectedCountries.push(this.countries[j]);
              break;
            }
          }
        }

        this.formGroup?.controls.countrySelectionType.setValue(result[3]);
        values = result[4].split(',');
        temp = "";
        for (let i = 0; i < values.length; i++) {
          temp += values[i] + '\n';
        }

        this.formGroup.controls.ipAddresses.setValue(temp);
        this.formGroup?.controls.ipAddressSelectionType.setValue(result[5]);

      });

    this.filteredCountries = this.countryCtrl.valueChanges.pipe(
      startWith(null),
      map((country: any | null) => (country ? this._filter(country) : this.countries.slice())),
    );
  }

  buildForm() {
    this.formGroup = new FormGroup({
      countrySelectionType: new FormControl("bl"),
      countries: this.countryCtrl,
      ipAddressSelectionType: new FormControl("bl"),
      ipAddresses: new FormControl(),
      whitelistedUsernames: new FormControl(),
      blacklistedUsernames: new FormControl()
    })
  }

  get blCountry(): boolean {
    return this.formGroup?.controls.countrySelectionType.value == "bl";
  }

  get blIpAddress(): boolean {
    return this.formGroup?.controls.ipAddressSelectionType.value == "bl";
  }

  update() {
    let countryCodeList = [];
    for (let i = 0; i < this.selectedCountries.length; i++) {
      countryCodeList.push(this.selectedCountries[i].countryCode);
    }

    let countryCodes = countryCodeList.join(',');
    let settings: any[] = [];
    settings.push({ Name: 'system:countries', Value: countryCodes });
    settings.push({ Name: 'system:blwlcountries', Value: this.formGroup.controls.countrySelectionType.value });
    settings.push({ Name: 'system:blwlipaddresses', Value: this.formGroup.controls.ipAddressSelectionType.value });
    settings.push({ Name: 'system:ipaddresses', Value: this.formGroup.controls.ipAddresses.value.replace('\n', ',') });
    this.systemService.setIds(settings).subscribe({
      next: (result) => { },
      error: (error) => { }
    });
  }

  addCountry(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      for (let i = 0; i < this.countries.length; i++) {
        if (this.countries[i].value == value) {
          this.selectedCountries.push(this.countries[i]);
          break;
        }
      }
    }

    // Clear the input value
    event.chipInput!.clear();

    this.countryCtrl.setValue(null);
  }

  removeCountry(country: string): void {
    for (let i = 0; i < this.selectedCountries.length; i++) {
      if (this.selectedCountries[i].value == country) {
        this.selectedCountries.splice(i, 1);
        return;
      }
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    for (let i = 0; i < this.countries.length; i++) {
      if (this.countries[i].value + " (" + this.countries[i].countryCode + ")" == event.option.viewValue) {
        this.selectedCountries.push(this.countries[i]);
        break;
      }
    }

    if (this.countryInput != undefined) {
      this.countryInput.nativeElement.value = '';
    }

    this.countryCtrl.setValue(null);
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country => country.value.toLowerCase().startsWith(filterValue));
  }
}
