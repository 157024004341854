import { Component, ElementRef, Input, Output, EventEmitter, OnInit } from '@angular/core';
import * as WebDataRocks from '@webdatarocks/webdatarocks';

@Component({
  selector: 'app-pivot-table',
  templateUrl: './pivot-table.component.html',
  styleUrl: './pivot-table.component.css'
})
export class PivotTableComponent implements OnInit {
  // params
  @Input() toolbar: boolean;
  @Input() width: string | number;
  @Input() height: string | number;
  @Input()
  get report(): any {
    return this._report;
  }
  set report(value) {
    this._report = value;
    this.createPivotTable();
    this.webDataRocks.setReport(value);
  }
  private _report: WebDataRocks.Report;

  @Input() global: WebDataRocks.Report;
  @Input() customizeCell: (cell: WebDataRocks.CellBuilder, data: WebDataRocks.CellData) => void;
  // events
  @Output() cellclick: EventEmitter<WebDataRocks.CellData> = new EventEmitter();
  @Output() celldoubleclick: EventEmitter<WebDataRocks.CellData> = new EventEmitter();
  @Output() dataerror: EventEmitter<object> = new EventEmitter();
  @Output() datafilecancelled: EventEmitter<object> = new EventEmitter();
  @Output() dataloaded: EventEmitter<object> = new EventEmitter();
  @Output() datachanged: EventEmitter<object> = new EventEmitter();
  @Output() fieldslistclose: EventEmitter<object> = new EventEmitter();
  @Output() fieldslistopen: EventEmitter<object> = new EventEmitter();
  @Output() filteropen: EventEmitter<object> = new EventEmitter();
  @Output() fullscreen: EventEmitter<object> = new EventEmitter();
  @Output() loadingdata: EventEmitter<object> = new EventEmitter();
  @Output() loadinglocalization: EventEmitter<object> = new EventEmitter();
  @Output() loadingreportfile: EventEmitter<object> = new EventEmitter();
  @Output() localizationerror: EventEmitter<object> = new EventEmitter();
  @Output() localizationloaded: EventEmitter<object> = new EventEmitter();
  @Output() openingreportfile: EventEmitter<object> = new EventEmitter();
  @Output() querycomplete: EventEmitter<object> = new EventEmitter();
  @Output() queryerror: EventEmitter<object> = new EventEmitter();
  @Output() ready: EventEmitter<WebDataRocks.Pivot> = new EventEmitter();
  @Output() reportchange: EventEmitter<object> = new EventEmitter();
  @Output() reportcomplete: EventEmitter<object> = new EventEmitter();
  @Output() reportfilecancelled: EventEmitter<object> = new EventEmitter();
  @Output() reportfileerror: EventEmitter<object> = new EventEmitter();
  @Output() reportfileloaded: EventEmitter<object> = new EventEmitter();
  @Output() runningquery: EventEmitter<object> = new EventEmitter();
  @Output() update: EventEmitter<object> = new EventEmitter();
  @Output() beforetoolbarcreated: EventEmitter<object> = new EventEmitter();
  @Output() aftergriddraw: EventEmitter<object> = new EventEmitter();
  @Output() beforegriddraw: EventEmitter<object> = new EventEmitter();
  // api
  public webDataRocks: WebDataRocks.Pivot;
  // private
  private root: HTMLElement;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.createPivotTable();
  }

  expandAll() {
    this.webDataRocks.expandAllData();
  }

  collaspeAll() {
    this.webDataRocks.collapseAllData();
  }

  createPivotTable() {
    if (this.webDataRocks != null) {
      return;
    }

    this.root = this.el.nativeElement as HTMLElement;
    this.webDataRocks = new WebDataRocks({
      container: this.root.getElementsByClassName('wbr-ng-wrapper')[0],
      width: this.width,
      height: this.height,
      toolbar: this.toolbar,
      report: this.report,
      global: this.global,
      customizeCell: this.customizeCell,
      cellclick: (cell: WebDataRocks.CellData) => this.cellclick.next(cell),
      celldoubleclick: (cell: WebDataRocks.CellData) => this.celldoubleclick.next(cell),
      dataerror: (event: object) => this.dataerror.next(event),
      datafilecancelled: () => this.datafilecancelled.next(null),
      dataloaded: () => this.dataloaded.next(null),
      datachanged: (event: object) => this.datachanged.next(event),
      fieldslistclose: () => this.fieldslistclose.next(null),
      fieldslistopen: () => this.fieldslistopen.next(null),
      filteropen: () => this.filteropen.next(null),
      loadingdata: () => this.loadingdata.next(null),
      loadinglocalization: () => this.loadinglocalization.next(null),
      loadingreportfile: () => this.loadingreportfile.next(null),
      localizationerror: () => this.localizationerror.next(null),
      localizationloaded: () => this.localizationloaded.next(null),
      openingreportfile: () => this.openingreportfile.next(null),
      querycomplete: () => this.querycomplete.next(null),
      queryerror: () => this.queryerror.next(null),
      ready: () => this.ready.next(this.webDataRocks),
      reportchange: () => this.reportchange.next(null),
      reportcomplete: () => this.reportcomplete.next(null),
      reportfilecancelled: () => this.reportfilecancelled.next(null),
      reportfileerror: () => this.reportfileerror.next(null),
      reportfileloaded: () => this.reportfileloaded.next(null),
      runningquery: () => this.runningquery.next(null),
      update: () => this.update.next(null),
      beforetoolbarcreated: (toolbar: object) => this.beforetoolbarcreated.next(toolbar),
      aftergriddraw: (event: object) => this.aftergriddraw.next(event),
      beforegriddraw: (event: object) => this.beforegriddraw.next(event)
    });
  }

}
