<div>
  <div *ngIf="canSeeTabs()" class="fieldRow">
    <div>
      <mat-button-toggle-group #group="matButtonToggleGroup" [value]="status.toString()" (change)="getSurveysWithStatus($event.value)">
        <mat-button-toggle aria-label="Drafts" value="1" [hidden]="totals[0] == 0">
          Drafts ({{totals[0]}})
        </mat-button-toggle>
        <mat-button-toggle aria-label="Scheduled" value="3" [hidden]="totals[1] == 0">
          Scheduled ({{totals[1]}})
        </mat-button-toggle>
        <mat-button-toggle aria-label="Live" value="2" [hidden]="totals[2] == 0">
          Live ({{totals[2]}})
        </mat-button-toggle>
        <mat-button-toggle aria-label="Closed" value="4" [hidden]="totals[3] == 0">
          Closed ({{totals[3]}})
        </mat-button-toggle>
      </mat-button-toggle-group>
      &nbsp;&nbsp;
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input type="text" matInput placeholder="Search" [(ngModel)]="searchKey" autocomplete="off" (keydown)="applyFilter($event)">
        <button matSuffix mat-icon-button aria-label="Clear" *ngIf="searchKey" (click)="onSearchClear($event)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>&nbsp;&nbsp;
    </div>
    <div>
      <button mat-icon-button color="primary" (click)="getSurveys()">
        <mat-icon>refresh</mat-icon>
      </button>&nbsp;&nbsp;
    </div>
    <div>
      <button mat-raised-button [matMenuTriggerFor]="newMenu" color="primary">
        <span>New Survey</span>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>&nbsp;&nbsp;
      <mat-menu #newMenu="matMenu">
        <button mat-menu-item (click)="newSurveyFromScratchDialog()">
          <span>Start from scratch</span>
        </button>
        <button mat-menu-item routerLink="/startfromtemplate">
          <span>Start from template</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div>
    &nbsp;
  </div>
  <div *ngIf="!canSeeTabs()" style="margin: 10px auto;text-align:center;width:75%">
    You are a guest to this party and can only view what is going on. Unfortunately nothing is happening!
  </div>
  <div *ngIf="canSeeTabs()" class="mat-elevation-z1">

    <div class="inbox-table-container custom-scroll-bar">
      <table mat-table #table [dataSource]="surveys"
             multiTemplateDataRows
             matSort
             matSortActive="created"
             matSortDisableClear matSortDirection="desc">

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let survey"><a href="javascript:void(0)" (click)="edit(survey)">{{survey.Name}}</a><br /><span class="sub-title">({{survey?.SurveyID}})</span></td>
        </ng-container>

        <!-- Created Column -->
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
          <td mat-cell *matCellDef="let survey"> {{survey.Created |  date: 'short': '' : translate.currentLang}} </td>
        </ng-container>

        <!-- Modified Column -->
        <ng-container matColumnDef="modified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Modified </th>
          <td mat-cell *matCellDef="let survey"> {{survey.LastUpdated | date:'short': '' : translate.currentLang}} </td>
        </ng-container>

        <!-- Launch Column -->
        <ng-container matColumnDef="launch">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Scheduled to Launch </th>
          <td mat-cell *matCellDef="let survey">
            <div class="fieldRow">
              <div>
                {{survey.Started | date:'short': '' : translate.currentLang}}
              </div>
              <div class="dataIO-schedule-div">
                <mat-icon *ngIf="survey.RepeatSchedule" class="dataIO-automatedIcon">schedule</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Go Live Column -->
        <ng-container matColumnDef="golive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Launched </th>
          <td mat-cell *matCellDef="let survey">
            <div class="fieldRow">
              <div>
                {{survey.Launched | date:'short': '' : translate.currentLang}}
              </div>
              <div class="dataIO-schedule-div">
                <mat-icon *ngIf="survey.RepeatSchedule" class="dataIO-automatedIcon">schedule</mat-icon>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- End Date Column -->
        <ng-container matColumnDef="enddate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> End </th>
          <td mat-cell *matCellDef="let survey"> {{survey.Ended | date:'short': '' : translate.currentLang}} </td>
        </ng-container>

        <!-- Channel Column -->
        <ng-container matColumnDef="channel">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Channel </th>
          <td mat-cell *matCellDef="let survey">

            <!--<mat-icon matTooltip="{{channelTooltip(survey)}}" matTooltipPosition="above">{{channel(survey)}}</mat-icon>-->
            <i class="{{channel(survey)}}" placement="top" ngbTooltip="{{channelTooltip(survey)}}" tooltipClass="tooltip-custom-class"></i>
          </td>
        </ng-container>

        <!-- Question count Column -->
        <ng-container matColumnDef="questions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Questions </th>
          <td mat-cell *matCellDef="let survey"> {{survey.QuestionCount}} </td>
        </ng-container>

        <!-- % Completed Column -->
        <ng-container matColumnDef="completed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Completed </th>
          <td mat-cell *matCellDef="let survey"> {{percentageCompleted(survey) | number: '1.0-0'}}% </td>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="progress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Progress </th>
          <td mat-cell *matCellDef="let survey">
            <div class="surv-dashBrd-progress-container">
              <div class="surv-dashBrd-progressBar-div">
                <mat-progress-bar mode="determinate" [value]="percentageCompleted(survey)"></mat-progress-bar>
              </div>
              <div class="ml-5">
                <span>{{survey.CompletionCount}}</span>/<span>{{survey.PanellistCount}}</span>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- Action column -->
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let survey">
            <div>
              <button mat-icon-button (click)="onContextMenu($event, survey)">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getColumnsToDisplay(); sticky: true"></tr>
        <tr mat-row *matRowDef="let survey; columns: getColumnsToDisplay();"
            class="users-element-row" (contextmenu)="onContextMenu($event, survey)">
        </tr>
      </table>
    </div>
    <mat-paginator [pageSize]="surveyPageSize" [length]="getTotal()"></mat-paginator>
  </div>
  <div #contextMenuTrigger="matMenuTrigger" [ngStyle]="{position:'fixed',top:contextMenuPosition.y,left:contextMenuPosition.x}" [matMenuTriggerFor]="contextMenu"></div>
  <mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent let-survey="survey">
      <button mat-menu-item (click)="edit(survey)">
        <mat-icon>edit</mat-icon>
        <span>Edit Survey</span>
      </button>
      <button *ngIf="canEdit" mat-menu-item (click)="copySurvey(survey)">
        <mat-icon>file_copy</mat-icon>
        <span>Copy Survey</span>
      </button>
      <button *ngIf="canEdit && checkStatus([2,3], survey)" mat-menu-item (click)="abandonSurvey(survey)">
        <mat-icon>timer_off</mat-icon>
        <span>Abandon Survey</span>
      </button>
      <button *ngIf="canEdit && checkStatus([2,4,5,6], survey)" mat-menu-item (click)="relaunchSurvey(survey)">
        <mat-icon>rocket_launch</mat-icon>
        <span>Relaunch Survey</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item [matMenuTriggerFor]="testSurvey">
        <mat-icon>play_arrow</mat-icon>
        <span>Test Survey</span>
      </button>
      <mat-divider *ngIf="checkStatus([2, 4, 5, 6], survey)"></mat-divider>
      <button *ngIf="checkStatus([2, 4, 6], survey)" mat-menu-item (click)="viewResults(survey)">
        <mat-icon>signal_cellular_alt</mat-icon>
        <span>Results</span>
      </button>
      <button *ngIf="checkStatus([2, 4, 5, 6], survey)" mat-menu-item (click)="viewDelivery(survey)">
        <mat-icon>assessment</mat-icon>
        <span>Data Analysis</span>
      </button>
      <button *ngIf="checkStatus([2, 4, 5, 6], survey)" mat-menu-item (click)="surveyProperties(survey)">
        <mat-icon>settings</mat-icon>
        <span>Properties</span>
      </button>
      <mat-divider *ngIf="canEdit"></mat-divider>
      <button *ngIf="canEdit" mat-menu-item (click)="deleteSurvey(survey)">
        <mat-icon>delete</mat-icon>
        <span>Delete Survey</span>
      </button>
      <mat-divider *ngIf="canEdit"></mat-divider>
      <button *ngIf="canEdit" mat-menu-item (click)="exportScript(survey)">
        <mat-icon>file_download</mat-icon>
        <span>Export Survey Script</span>
      </button>
      <mat-divider *ngIf="canEdit"></mat-divider>
      <button *ngIf="canEdit" mat-menu-item (click)="transfer(survey)">
        <mat-icon>drive_file_move_outline</mat-icon>
        <span>Transfer Survey</span>
      </button>
    </ng-template>
  </mat-menu>
  <mat-menu #menu="matMenu">
    <ng-template matMenuContent let-survey="survey">
      <button *ngIf="checkStatus([1], survey)" mat-menu-item (click)="editSurvey(survey, true)">
        <mat-icon>edit</mat-icon>
        <span>Edit Survey</span>
      </button>
      <button *ngIf="canEdit" mat-menu-item (click)="copySurvey(survey)">
        <mat-icon>file_copy</mat-icon>
        <span>Copy Survey</span>
      </button>
      <button *ngIf="canEdit && checkStatus([2], survey)" mat-menu-item (click)="abandonSurvey(survey)">
        <mat-icon>timer_off</mat-icon>
        <span>Abandon Survey</span>
      </button>
      <button *ngIf="canEdit && checkStatus([2,4,5,6], survey)" mat-menu-item (click)="relaunchSurvey(survey)">
        <mat-icon>rocket_launch</mat-icon>
        <span>Relaunch Survey</span>
      </button>
      <mat-divider></mat-divider>
      <button mat-menu-item [matMenuTriggerFor]="testSurvey">
        <mat-icon>play_arrow</mat-icon>
        <span>Test Survey</span>
      </button>
      <mat-divider *ngIf="checkStatus([2, 4, 5, 6], survey)"></mat-divider>
      <button *ngIf="checkStatus([2, 4, 6], survey)" mat-menu-item (click)="viewResults(survey)">
        <mat-icon>signal_cellular_alt</mat-icon>
        <span>Results</span>
      </button>
      <button *ngIf="checkStatus([2, 4, 5, 6], survey)" mat-menu-item (click)="viewDelivery(survey)">
        <mat-icon>assessment</mat-icon>
        <span>Data Analysis</span>
      </button>
      <button *ngIf="checkStatus([2, 4, 5, 6], survey)" mat-menu-item (click)="surveyProperties(survey)">
        <mat-icon>settings</mat-icon>
        <span>Properties</span>
      </button>
      <mat-divider *ngIf="canEdit"></mat-divider>
      <button *ngIf="canEdit" mat-menu-item (click)="deleteSurvey(survey)">
        <mat-icon>delete</mat-icon>
        <span>Delete Survey</span>
      </button>
      <mat-divider *ngIf="canEdit"></mat-divider>
      <button *ngIf="canEdit" mat-menu-item (click)="exportScript(survey)">
        <mat-icon>file_download</mat-icon>
        <span>Export Survey Script</span>
      </button>
      <mat-divider *ngIf="canEdit"></mat-divider>
      <button mat-menu-item (click)="transfer(survey)">
        <mat-icon>drive_file_move_outline</mat-icon>
        <span>Transfer Survey</span>
      </button>
    </ng-template>
  </mat-menu>
  <mat-menu #testSurvey="matMenu" xPosition="after">
    <button mat-menu-item (click)="previewInterview(survey)">
      <mat-icon>play_arrow</mat-icon>
      <span>Preview</span>
    </button>
    <button mat-menu-item (click)="testOnOtherDevices(survey)">
      <mat-icon>qr_code_2</mat-icon>
      <span>Test On Other Devices</span>
    </button>
    <button *ngIf="canEdit" mat-menu-item (click)="testInterview(survey)">
      <mat-icon>bug_report</mat-icon>
      <span>Generate Test Data</span>
    </button>
  </mat-menu>
</div>
