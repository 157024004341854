import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AuthenticationService, OrgRole, Organization, SystemInvitation } from '../authentication.Service';
import { UserProfile } from '../user.Service';

@Component({
  selector: 'invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.css']
})
export class InvitationsComponent implements OnInit {

  invitations: SystemInvitation[] = [];
  memberList: UserProfile[] = [];
  showAlert = false;
  alertMessage = "";
  showError = false;
  errorMessage = "";
  memberDataSource = new MatTableDataSource<UserProfile>();
  inviteDataSource = new MatTableDataSource<SystemInvitation>();
  resultsLength = 0;
  columnsToDisplay: string[] = [];

  constructor(private authService: AuthenticationService, private snackbar: MatSnackBar) { }

  ngOnInit(): void {
  }

  getName(invitation: SystemInvitation) {
    if (invitation.UserId === 0) {
      return invitation.EmailAddress;
    }

    return invitation.User.FirstName + " " + invitation.User.LastName;
  }

  get2FA(member: UserProfile): boolean {
    return member.TwoFA > 0;
  }

  getUserName(invitation: SystemInvitation) {
    if (invitation.UserId === 0) {
      return "";
    }

    return invitation.User.UserName;
  }

  isOwner(member: UserProfile): boolean {
    return this._org.OwnerId == member.UserID;
  }

  inviteAgain(invitation: SystemInvitation): boolean {
    this.authService.inviteAgain(invitation.ReferenceId, invitation.InvitationId).subscribe(
      result => {
        this.openSnackbar("Successfully invited again", "");
      },
      error => {
        this.openSnackbar("There was a problem sending the invite. Please contact Client Services", "Cancel");
      });
    return false;
  }

  deleteMember(member: UserProfile): boolean {
    let i = 0;
    for (i = 0; i < this.memberList.length; i++) {
      if (this.memberList[i].UserID == member.UserID) {
        break;
      }
    }

    this.authService.deleteMemberForOrganization(this.organization.OrganizationId, member.UserID);
    this.memberList.splice(i, 1);
    this.refresh();
    return false;
  }

  deleteInvitation(invite: SystemInvitation): boolean {
    let i = 0;
    for (i = 0; i < this.invitations.length; i++) {
      if (this.invitations[i].InvitationId == invite.InvitationId) {
        break;
      }
    }

    this.authService.deleteInvitation(this.invitations[i].InvitationId);
    this.invitations.splice(i, 1);
    this.organization.Invitations = this.invitations;
    this.refresh();
    return false;
  }

  role(member: UserProfile): string {
    if (this._org.Members?.length > 0 && this._org.Roles?.length > 0) {
      for (let i = 0; i < this._org.Members.length; i++) {
        if (this._org.Roles[i]?.UserId == member.UserID) {
          switch (this._org.Roles[i].RoleId) {
            case 6:
              return "Moderator";
            case 19:
              return "Guest";
            default:
              return "Owner";
          }
        }
      }
    }

    return "Owner";
  }

  changeRole(member: UserProfile, role: number) {
    for (let i = 0; i < this._org.Members.length; i++) {
      if (this._org.Roles[i].UserId == member.UserID) {
        if (this._org.Roles[i].RoleId == role) {
          return;
        }

        this._org.Roles[i].RoleId = role;
        break;
      }
    }

    this.authService.setRoleForOrganization(this._org.OrganizationId, member.UserID, role).subscribe();
  }

  makeOwner(member: UserProfile) {

  }

  getStatus(invitation: SystemInvitation) {
    switch (invitation.StatusId) {
      case 1:
        return "invited";
      case 2:
        return "member";
    }

    return "unknown status";
  }

  isMember(invitation: SystemInvitation) {
    return invitation.StatusId === 2;
  }

  refresh() {
    this.memberDataSource = new MatTableDataSource(this.memberList);
    this.inviteDataSource = new MatTableDataSource(this.invitations);
  }

  @Input()
  get show(): string {
    return this._show;
  }
  set show(value: string) {
    this._show = value;
    this.columnsToDisplay = this._show == "members" ? ["name", "2fa", "action"] : ["name", "action"];
  }
  private _show: string = "members";

  @Input()
  get organization(): Organization {
    return this._org;
  }
  set organization(value: Organization) {
    this._org = value;
    this.memberList = [];
    this.invitations = this._org.Invitations;
    let roles = [];
    if (this._org.Members?.length > 0) {
      for (let i = 0; i < this._org.Members.length; i++) {
        this.memberList.push(this._org.Members[i]);
      }
    }

    this.refresh();
  }
  private _org: Organization = new Organization();

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }

}
