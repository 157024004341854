import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { version, baseUrl } from "../../../environments/environment";
import { AuthenticationService, LoginModel, SocialLogin } from "../../authentication.Service";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable, Subject, Subscription, fromEvent } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SurveyService } from '../../survey.Service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from '@abacritt/angularx-social-login';
import { DeviceDetectorService } from 'ngx-device-detector';
import { IpAddressUtil, ZendeskUtil } from '../../utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FeatureTourDialogComponent } from '../../feature-tour-dialog/feature-tour-dialog.component';
import { fadeInAnimation, fadeInOnEnterAnimation, fadeOutOnLeaveAnimation, fadeInLeftAnimation, fadeInRightAnimation, rubberBandAnimation } from 'angular-animations';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { SystemService } from '../../system.Service';

@Component({
  selector: 'app-newlogin',
  templateUrl: './newlogin.component.html',
  styleUrl: './newlogin.component.css',
  animations: [
    fadeInOnEnterAnimation({ duration: 1000 }),
    fadeOutOnLeaveAnimation({ duration: 100 }),
    fadeInAnimation({ anchor: 'enter', duration: 1000 }),
    fadeInAnimation({ anchor: 'errorFadein', duration: 1000, delay: 100 })
  ]
})
export class NewloginComponent implements OnInit, OnDestroy {
  private config: {
    version: string
  };

  zendeskCheck: string[] = [
    "https://",
    "https://localhost",
    "https://staging.",
    "https://pg.",
    "https://ire.",
    "https://sg.",
    "https://ldn.",
    "https://ams.",
    "https://lbg"
  ];

  
  start: boolean = false;
  fadeInLeftState = false;
  formGroup: FormGroup;
  p1: boolean = false;
  p2: boolean = false;
  p3: boolean = false;
  private isTrue: boolean = false;
  errorMsg: string = "";
  displayError = false;
  displayWait = false;
  hide = true;
  breakpoint: number = 0;
  GoogleLoginProvider = GoogleLoginProvider;
  subscription?: Subscription;
  dialogRef: MatDialogRef<any, any>;
  enterDetails: boolean = true;
  loggingIn: boolean = false;
  showCaptcha: boolean = false;
  socialSignin: boolean = false;
  siteKey: string = "6LdvQ5AqAAAAAHInEePSGKXIs6O_WlDbeSbdmqbx";

  destroy = new Subject();

  destroy$ = this.destroy.asObservable();
  top: number = 0;

  usernameControl: FormControl;
  passwordControl: FormControl;
  usernameControlName: string = "";
  passwordControlName: string = "";

  captchaLevel: number = 1.9

  @ViewChild('loggingInDialog') loggingInDialog: TemplateRef<any>;

  constructor(private http: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service,
    private actRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private snackBar: MatSnackBar,
    private socialService: SocialAuthService,
    private breakpointObserver: BreakpointObserver,
    private surveyService: SurveyService,
    private systemService: SystemService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    public dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {
    if (this.authenticationService.getAccessToken() != "") {
      this.authenticationService.deleteAccessToken();
      try {
        this.socialService.signOut();
      }
      catch { }
    }

    this.systemService.getById("google:captchaLevel").subscribe(
      result => { this.captchaLevel = result.Value; }
    );


    this.authenticationService.logoutWithoutNavigate();
    let random = Math.random().toString().replace('.', '');
    this.usernameControl = new FormControl("", [Validators.required]);
    this.passwordControl = new FormControl("", [Validators.required]);
    this.usernameControlName = "sadasdn" + random;
    this.passwordControlName = "sddasdn" + random;

    this.formGroup = new FormGroup({
      deviceType: new FormControl(""),
      ipAddress: new FormControl(""),
      token: new FormControl("")
    });
    this.formGroup.addControl(this.usernameControlName, this.usernameControl);
    this.formGroup.addControl(this.passwordControlName, this.passwordControl);
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(map(result => result.matches));

  ngOnInit(): void {
    this.subscription = this.socialService.authState.subscribe((user) => {
      try {
        this.authenticationService.getIpAddress().subscribe(
          {
            next: (result) => {
              this.formGroup.controls.ipAddress.setValue(result.ip);
              this.subSocialLogin(user, result.ip);
            },
            error: (error) => {
              this.subSocialLogin(user, "");
            }
          });
      }
      catch (error) {
        console.warn("Unable to get device ip address", error);
      }
    });
  }

  subSocialLogin(user, ipAddress: string) {
    this.recaptchaV3Service.execute('login').subscribe((token) => this.handleSocialToken(3, token, user, ipAddress));
  }

  handleSocialToken(version: number, token: string, user: any, ipAddress: string) {
    this.enterDetails = false;
    this.loggingIn = true;
    this.showCaptcha = false;
    this.displayWait = true;
    let deviceInfo = this.deviceService.getDeviceInfo();
    this.formGroup.controls.deviceType.setValue(deviceInfo.userAgent);
    this.authenticationService.getIpAddress().subscribe(
      {
        next: (result) => {
          this.formGroup.controls.ipAddress.setValue(result.ip);
          this.subSocialLogin1a(version, token, user, ipAddress);
        },
        error: (error) => {
          this.formGroup.controls.ipAddress.setValue("");
          this.subSocialLogin1a(version, token, user, ipAddress);
        }
      });
    return false;
  }

  subSocialLogin1a(version: number, token: string, user, ipAddress: string) {
    this.authenticationService.testRcToken(version, token, ipAddress).subscribe(
      {
        next: (result) => {
          if (result.success && (version == 2 || result.score < this.captchaLevel)) {
            if (version == 3) {
              this.loggingIn = false;
              this.showCaptcha = true;
              return;
            }
          }

          this.subSocialLogin1b(user, ipAddress);
        },
        error: (error) => {
          this.generateError('We are unable to log you in. Please contact Client Services for further help.');
        }
      });
  }

  subSocialLogin1b(user, ipAddress) {
    let model = new SocialLogin();
    if (user != null) {
      model.EmailAddress = user.email;
      model.FirstName = user.firstName;
      model.LastName = user.lastName;
      model.PictureUrl = user.photoUrl;
      model.Provider = user.provider;
      model.UserID = user.id;
      model.IpAddress = ipAddress;
      let deviceInfo = this.deviceService.getDeviceInfo();
      model.DeviceType = deviceInfo.device + ":" + deviceInfo.deviceType + ":" + deviceInfo.browser;
      this.displayWait = true;
      this.authenticationService.socialLogin(model).subscribe({
        next: (result) => {
          if (result.Status == 0) {
            this.authenticationService.setAccessToken(result.Token);
            this.router.navigate(['../2fa']);
            return;
          }

          if (result.Status == 2) {
            this.authenticationService.setAccessToken(result.Token);
            this.router.navigate(['../2fasetup']);
            return;
          }

          this.displayWait = false;
          this.checkToken((result as any).Token);
        },
        error: (error) => {
          this.loggingIn = false;
          this.enterDetails = true;
          this.openSnackbar("There was a problem using the credentials", "Cancel");
          this.displayWait = false;
        }
      });
    }
    else {
      this.loggingIn = false;
      this.enterDetails = true;
      this.openSnackbar("There was a problem using the credentials", "Cancel");
      this.displayWait = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.destroy.next(this.destroy$);
  }

  ngAfterViewInit(): void {
    fromEvent(window, 'scroll').pipe(takeUntil(this.destroy$)).subscribe((e: Event) => {
      this.top = this.getYPosition(e);
    });

    Promise.resolve(null).then(() => {
      this.start = true;
    });
  }

  logInWithFacebook(): boolean {
    this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID);
    return false;
  }

  refreshGoogleToken(): void {
    this.displayWait = true;
    this.socialService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
    this.displayWait = false;
  }

  wait(ms) {
    var start = new Date().getTime();
    var end = start;
    while (end < start + ms) {
      end = new Date().getTime();
    }
  }

  resolvedCaptcha(event: any) {
    this.handleToken(2, event);
  }

  erroredCaptcha(event: any) {
    let a = event;
  }

  loginProcess() {
    this.recaptchaV3Service.execute('login').subscribe((token) => this.handleToken(3, token));
  }

  handleToken(version: number, token: string) {
    this.loggingIn = true;
    this.showCaptcha = false;
    this.formGroup.controls.token.setValue(token);
    this.usernameControl.setErrors(null);
    this.passwordControl.setErrors(null);
    if (this.formGroup.valid) {
      this.displayWait = true;
      let deviceInfo = this.deviceService.getDeviceInfo();
      this.formGroup.controls.deviceType.setValue(deviceInfo.userAgent);
      this.authenticationService.getIpAddress().subscribe(
        {
          next: (result) => {
            this.formGroup.controls.ipAddress.setValue(result.ip);
            this.subLogin(version);
          },
          error: (error) => {
            this.formGroup.controls.ipAddress.setValue("");
            this.subLogin(version);
            // console.warn("Unable to get device details", error);
            // this.generateError('We are unable to log you in, because we could not establish your country of origin');
          }
        });
    }

    return false;
  }

  subLogin(version: number) {
    this.authenticationService.testRcToken(version, this.formGroup.controls.token.value, this.formGroup.controls.ipAddress.value).subscribe(
      {
        next: (result) => {
          if (result.success && (version == 2 || (result.score <= this.captchaLevel && this.usernameControl.value != "admin"))) {
            if (version == 3) {
              this.loggingIn = false;
              this.showCaptcha = true;
              return;
            }
          }

          this.subLoginPart2();
        },
        error: (error) => {
          console.warn("There was a problem with the login", error);
          this.generateError('We are unable to log you in. Please contact Client Services for further help.');
          return;
        }
      });
  }

  generateError(message: string) {
    this.displayWait = false;
    this.loggingIn = false;
    this.enterDetails = true;
    this.displayError = true;
    this.usernameControl.setErrors({ invalid: '' });
    this.errorMsg = message;
    this.passwordControl.setErrors({ invalid: this.errorMsg });
  }

  subLoginPart2() {
    let model = new LoginModel();
    model.Username = this.usernameControl.value;
    model.Password = this.passwordControl.value;
    model.DeviceType = this.formGroup.controls.deviceType.value;
    model.IpAddress = this.formGroup.controls.ipAddress.value;
    this.authenticationService.loginNoCheck(model).subscribe({
      next: (result) => {
        this.displayWait = false;
        if (result.Status == 0) {
          this.authenticationService.setAccessToken(result.Token);
          this.router.navigate(['../2fa']);
          return;
        }

        if (result.Status == 2) {
          this.authenticationService.setAccessToken(result.Token);
          this.router.navigate(['../2fasetup']);
          return;
        }

        this.checkToken(result.Token);
      },
      error: (error) => {
        console.warn("There was a problem with the login", error);
        this.generateError('There is a problem with the credentials');
      }
    });
  }

  getError(): string {
    if (!this.displayError) {
      return '';
    }

    this.usernameControl.setErrors({ invalid: '' });
    // this.errorMsg = 'There is a problem with the credentials';
    this.passwordControl.setErrors({ invalid: this.errorMsg });
    return this.errorMsg;
  }

  private checkToken(token: any) {
    this.authenticationService.setAccessToken(token);
    let page = '';
    if (this.authenticationService.isAuthorized(['Admin'])) {
      page = '/system-dashboard';
      this.signIntoZenDesk(page);
    }
    else if (this.authenticationService.isAuthorized(['developer'])) {
      page = '/smshome/keys';
      this.signIntoZenDesk(page);
    }
    else {
      this.surveyService.getSurveyCount()
        .subscribe(response => {
          if (response > 0) {
            page = '/surveydashboard';
          } else {
            page = '/startfromtemplate';
          }

          if (this.authenticationService.loadSetting('homeTourDone') != "") {
            const dialogRef = this.dialog.open(FeatureTourDialogComponent, { disableClose: true });
          }
          this.signIntoZenDesk(page);
        },
          error => {
            if (error.status === 401) {
              this.router.navigate(['../']);
            }
          });
    }
  }

  private signIntoZenDesk(redirectTo: string) {
    let rt = window.location.href.toString();
    for (let i = 0; i < this.zendeskCheck.length; i++) {
      if (rt.startsWith(this.zendeskCheck[i])) {
        this.router.navigate(['..' + redirectTo]);
        return;
      }
    }

    this.authenticationService.zendeskCheck().subscribe(
      result => {
        ZendeskUtil.signin(result.Value, redirectTo);
      },
      error => {
        console.error("There was a problem with the login for Zendesk:" + error)
      });
  }

  checkStyle(): string {
    if (this.top > 5) {
      return 'mat-elevation-z2';
    }

    return '';
  }

  openTerms() {
    const url = this.router.createUrlTree(['/termsofservice'])
    window.open(url.toString(), '_blank')
  }

  openPrivacy() {
    const url = this.router.createUrlTree(['/privacystatement'])
    window.open(url.toString(), '_blank')
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  getYPosition(e): number {
    return e.target.scrollingElement.scrollTop;
  }

  startForFree() {
    this.start = !this.start;
  }

  onAppear() {
    let a = 0;
  }

  private openSnackbar(message: string, action: string) {
    if (action == "") {
      this.snackbar.open(message, action, { duration: 2000 });
    }
    else {
      this.snackbar.open(message, action, { duration: 7000 });
    }
  }
}
