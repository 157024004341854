import { Component } from '@angular/core';
import { colorSets } from '../../../charts/color-sets.service';
import { LegendPosition } from '@swimlane/ngx-charts';
import { multi } from '../../../data.service';
import { GroupReportData } from '../../../question.service';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../../authentication.Service';
import { MessageService, MessageStatsSearchModel, TranLogStatsModel, TranLogStatsResults, TranlogUserStatsModel } from '../../../message.Service';
import { SnackbarDefaultComponent } from '../../../snackbar/snackbar-default.component';
import { UserService } from '../../../user.Service';
import * as moment from 'moment-timezone';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PivotTableComponent } from '../../pivot-table/pivot-table.component';

@Component({
  selector: 'app-traffic-dashboard',
  templateUrl: './traffic-dashboard.component.html',
  styleUrl: './traffic-dashboard.component.css'
})
export class TrafficDashboardComponent {
  timezone: string = moment.tz.guess();
  startDate: Date;
  endDate: Date;
  stats: TranLogStatsModel[] = null;

  multi: any[] = [];
  colorSets: any;
  schemeType: string = 'linear';
  legend = true;
  legendTitle = '';
  legendPosition = LegendPosition.Below;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  statuses: any[] = [];
  selectedStatuses: boolean[] = [];
  messages: number = 0;
  segments: number = 0;

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = '';
  showYAxisLabel = true;
  yAxisLabel = '';
  showGraph: boolean = false;
  allChange: boolean = false;
  previousRange: string = "";
  nothingShowing: boolean = false;

  formGroup: FormGroup;

  sb: MatSnackBarRef<any> | undefined;

  receiptValues: any[] = [
    { "name": "Delivered", "value": 0, "color": "#00a0b0" },
    { "name": "Submitted to Carrier", "value": 1, "color": "#edc951" },
    { "name": "Buffered For Retry", "value": 2, "color": "#edc951" },
    { "name": "Failed", "value": 3, "color": "#cc2a36" },
    { "name": "Unknown", "value": 4, "color": "#cc2a36" },
    { "name": "System Error", "value": 5, "color": "#cc2a36" },
    { "name": "Blacklisted", "value": 6, "color": "#000000" },
    { "name": "Destination Busy", "value": 7, "color": "#eb6841" },
    { "name": "Number UnRoutable", "value": 8, "color": "#cc2a36" },
    { "name": "Destination Unavailable", "value": 9, "color": "#eb6841" },
    { "name": "Blocked", "value": 10, "color": "#eb6841" },
    { "name": "Success", "value": 11, "color": "#00a0b0" },
    { "name": "Invalid Destination Number", "value": 12, "color": "#cc2a36" },
    { "name": "Inbound", "value": 13, "color": "#83d0c9" },
    { "name": "OnStop", "value": 14, "color": "#ffffff" },
    { "name": "Read", "value": 15, "color": "#00a0b0" },
    { "name": "Invalid Source", "value": 16, "color": "#cc2a36" },
    { "name": "Undelivered", "value": 17, "color": "#eb6841" },
    { "name": "Content Related Error", "value": 18, "color": "#cc2a36" },
    { "name": "No Longer Active", "value": 19, "color": "#cc2a36" },
    { "name": "Network Error", "value": 20, "color": "#cc2a36" },
    { "name": "Age Restriction", "value": 21, "color": "#eb6841" },
    { "name": "Insufficient Funds", "value": 22, "color": "#eb6841" },
    { "name": "Regulation", "value": 23, "color": "#eb6841" },
    { "name": "Throttled", "value": 24, "color": "#eb6841" },
    { "name": "Equipement Permenent Error", "value": 25, "color": "#cc2a36" },
    { "name": "Equipement Temporary Error", "value": 26, "color": "#eb6841" },
    { "name": "Rejected", "value": 27, "color": "#cc2a36" },
    { "name": "Invalid Receipt Request", "value": 28, "color": "#edc951" },
    { "name": "Unreachable", "value": 29, "color": "#eb6841" },
    { "name": "Spam", "value": 30, "color": "#cc2a36" },
    { "name": "Queued for Submission", "value": 31, "color": "#edc951" },
    { "name": "Expired", "value": 32, "color": "#eb6841" }
  ];

  colorScheme: any = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5',
      '#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5',
      '#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5',
      '#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5',
      '#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5',
      '#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5',
      '#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  view: [string, string] = ["100%", "80%"];

  results: GroupReportData[];

  jsonData = null;
  /*[
    {
      "DateAdded": { type: "datetime" },
      "GatewayName": { type: "string" },
      "OwnerId": { type: "number" },
      "Account": { type: "string" },
      "ReceiptStauts": { type: "number" },
      "ReceiptStatusDescription": { type: "string" },
      "Segments": { type: "number" },
      "Messages": { type: "number" }
    }];*/
  dataReady: boolean = false;
  columnNames = [];
  pivot = {
    "dataSource": {
      "dataSourceType": "json",
      "data": this.jsonData
    },
    "slice": {
      "rows": [
        { "uniqueName": "GatewayName", "sort": "asc" },
        { "uniqueName": "Account", "sort": "asc" },
        { "uniqueName": "Country", "sort": "asc" },
        { "uniqueName": "Survey", "sort": "asc" },
        { "uniqueName": "Date", "sort": "asc" },
        { "uniqueName": "DateOnly", "sort": "asc" }
      ],
      "columns": [
        { "uniqueName": "ReceiptStatusDescription", "sort": "asc" }
      ],
      "measures": [
        { "uniqueName": "Segments", "aggregatation": "sum", "caption": "Segments" },
        { "uniqueName": "Percentage", "formula": "percentofrow(\"Segments\")", "individual": true, "format": "percent", "caption": "Percentage" }
      ],
      "expands": {
        "expandAll": false,
      },
      "drills": {
        "drillAll": false
      }
    },
    "options": {
      "grid": {
        "type": "compact",
        "title": "",
        "showFilter": true,
        "showHeaders": true,
        "showTotals": true,
        "showGrandTotals": "on",
        "showHierarchies": true,
        "showHierarchyCaptions": true,
        "showReportFiltersArea": true
      },
      "configuratorActive": false,
      "configuratorButton": true,
      "showAggregations": true,
      "showCalculatedValuesButton": true,
      "drillThrough": false,
      "showDrillThroughConfigurator": false,
      "sorting": "on",
      "datePattern": "dd/MM/yyyy",
      "dateTimePattern": "dd/MM/yyyy HH:mm:ss",
      "saveAllFormats": false,
      "showDefaultSlice": true,
      "defaultHierarchySortName": "asc"
    },
    "formats": [
      {
        "name": "",
        "thousandsSeparator": ",",
        "decimalSeparator": ".",
        "decimalPlaces": 0,
        "maxSymbols": 20,
        "currencySymbol": "",
        "currencySymbolAlign": "left",
        "nullValue": " ",
        "infinityValue": "Infinity",
        "divideByZeroValue": "Infinity"
      },
      {
        "name": "percent",
        "thousandsSeparator": ",",
        "decimalSeparator": ".",
        "decimalPlaces": 2,
        "maxSymbols": 20,
        "currencySymbol": "%",
        "currencySymbolAlign": "right",
        "nullValue": " ",
        "infinityValue": "Infinity",
        "divideByZeroValue": "Infinity"
      }
    ],
    "conditions": [
      {
        "formula": "#value > 0 AND #value < 40",
        "measure": "Percentage",
        "format": {
          "backgroundColor": "#C5E1A5",
        }
      },
      {
        "formula": "#value > 41 AND #value < 80",
        "measure": "Percentage",
        "format": {
          "backgroundColor": "#00e64d",
        }
      },
      {
        "formula": "#value > 81 AND #value <= 100",
        "measure": "Percentage",
        "format": {
          "backgroundColor": "#00b33c",
        }
      }

    ]
  }

  pivotResults: any = null;

  @ViewChild('selectAll') selectAll: MatCheckbox;
  @ViewChild('pivotTable') pivotTable: PivotTableComponent | undefined;

  aggregators = ['Gateway', 'Country', 'Account', 'Date', 'Survey'];

  constructor(private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private snackbar: MatSnackBar) {
    Object.assign(this, { multi, colorSets });

    this.setToday(24);
    this.formGroup = new FormGroup({
      DateRange: new FormControl("Range"),
      StartDate: new FormControl(this.startDate, [Validators.required]),
      EndDate: new FormControl(this.endDate, [Validators.required]),
      MessageType: new FormControl("Segments", [Validators.required]),
      ViewType: new FormControl(2),
      Users: new FormControl([], [Validators.required]),
      Aggregator: new FormControl(1),
      Aggregators: new FormControl(this.aggregators)
    });
    this.previousRange = this.formGroup.controls.DateRange.value;
    this.colorSets = this.colorScheme;
  }

  getIconForAggregator(aggregator: string) {
    switch (aggregator) {
      case "Gateway":
        return "fence";
      case "Account":
        return "person_outline";
      case "Survey":
        return "poll";
      case "Country":
        return "flag";
      case "Date":
        return "schedule";
    }

    return "local_hospotial";
  }

  expand() {
    this.pivotTable.expandAll();
  }

  collaspe() {
    this.pivotTable.collaspeAll();
  }

  checkToolbar(toolbar) {
    const tabs = toolbar.getTabs();
    const traffic = this;
    toolbar.getTabs = function () {
      delete tabs[0];
      delete tabs[1];
      delete tabs[2];
      tabs.unshift({
        id: "wdr-tab-refresh",
        title: "Refresh",
        handler: () => {
          return new Promise(() => {
            setTimeout(() => {
              traffic.getMessages();
            })
          })
        },
        icon: this.icons.connect
      });
      return tabs;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.aggregators, event.previousIndex, event.currentIndex);
    this.setAggregators();
  }

  calculateAggregators() {
    let a = {
      "rows": [
      ]
    };

    for (let i = 0; i < this.aggregators.length; i++) {
      if (this.formGroup.controls.Aggregators.value.includes(this.aggregators[i])) {
        let value = "";
        switch (this.aggregators[i]) {
          case "Gateway":
            value = "GatewayName";
            break;
          case "Date":
            value = "Date";
            const days = this.getDays();
            if (days > 7) {
              value = "DateOnly";
            }

            break;
          default:
            value = this.aggregators[i];
            break;
        }
        a.rows.push({ "uniqueName": value, "sort": "asc" });
      }
    }

    return a;
  }

  setAggregators() {
    let aggregators = this.calculateAggregators();
    this.pivot.slice.rows = aggregators.rows;
    if (this.pivotTable != null && this.pivotTable != undefined) {
      this.pivotTable.report = this.pivotResults;
    }
  }

  getDateRangeType(): string {
    return this.formGroup.controls.DateRange.value;
  }

  getViewType(): number {
    return this.formGroup.controls.ViewType.value;
  }

  setDateRange() {
    if (this.previousRange == this.formGroup.controls.DateRange.value) {
      return;
    }

    switch (this.formGroup.controls.DateRange.value) {
      case "Today":
        this.setToday(24);
        break;
      case "Weekly":
        this.setBackFromToday(24 * 7);
        break;
      case "Monthly":
        this.setMonthlyRange();
        break;
      case "Range":
        break;
    }

    this.getMessages();
  }

  setMonthlyRange() {
    let month = this.startDate.getMonth();
    let year = this.startDate.getFullYear();
    this.startDate = new Date();
    this.startDate.setHours(0, 0, 0, 0);
    this.startDate.setDate(1);
    this.startDate.setMonth(month);
    this.startDate.setFullYear(year);
    this.endDate = new Date();
    this.endDate.setHours(0, 0, 0, 0);
    this.endDate.setDate(1);
    this.endDate.setMonth(this.startDate.getMonth() == 11 ? 0 : this.startDate.getMonth() + 1);
    this.endDate.setFullYear(this.startDate.getMonth() == 11 ? year + 1 : year)
  }

  setBackFromToday(hours: number) {
    this.startDate = new Date()
    this.startDate.setHours(0, 0, 0, 0);
    this.startDate.setTime(this.startDate.getTime() - (hours * 60 * 60 * 1000));
    this.setTheRestOfTheTimeSpan(hours);
  }

  setToday(hours: number) {
    this.startDate = new Date();
    this.setTheRestOfTheTimeSpan(hours);
  }

  setFromToday(hours: number) {
    this.startDate = new Date();
  }

  setAnalysisMeasure() {
    this.buildSeries();
  }

  adjustTimeBy(hours: number, span: number) {
  }

  setTheRestOfTheTimeSpan(hours: number) {
    this.startDate.setHours(0, 0, 0, 0);
    this.endDate = new Date();
    this.endDate.setTime(this.startDate.getTime() + (hours * 60 * 60 * 1000));
    this.endDate.setHours(0, 0, 0, 0);
  }

  moveBack() {
    switch (this.formGroup.controls.DateRange.value) {
      case "Today":
        this.startDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate() - 1);
        this.setTheRestOfTheTimeSpan(24);
        break;
      case "Weekly":
        break;
      case "Monthly":
        let month = this.startDate.getMonth();
        let year = this.startDate.getFullYear();
        this.startDate.setMonth(month == 0 ? 11 : month - 1);
        this.startDate.setFullYear(month == 0 ? year - 1 : year);
        this.setMonthlyRange();
        break;
    }

    this.getMessages();
  }

  moveForward() {
    switch (this.formGroup.controls.DateRange.value) {
      case "Today":
        this.startDate = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), this.startDate.getDate() + 1);
        this.setTheRestOfTheTimeSpan(24);
        break;
      case "Weekly":
        break;
      case "Monthly":
        let month = this.startDate.getMonth();
        let year = this.startDate.getFullYear();
        this.startDate.setMonth(month == 11 ? 0 : month + 1);
        this.startDate.setFullYear(month == 11 ? year + 1 : year);
        this.setMonthlyRange();
        break;
    }

    this.getMessages();
  }

  get isAdminUser(): boolean {
    return this.authenticationService.isAuthorized(['Admin']);
  }

  get chartType(): number {
    return this.formGroup.controls.ViewType.value;
  }

  getMessagesWithSearch() {
    this.formGroup.controls.DateRange.setValue("Range");
    this.getMessages();
  }

  getDays(): number {
    const oneDay = 1000 * 60 * 60 * 24; // milliseconds in one day
    const diffInTime = this.endDate.getTime() - this.startDate.getTime(); // difference in milliseconds
    return Math.round(diffInTime / oneDay); // convert to days and round
  }

  getMessages() {
    this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
    let model = new MessageStatsSearchModel();
    model.From = this.startDate ?? new Date("2024-01-01");
    model.To = this.endDate ?? new Date("2040-01-01");
    model.GatewayName = '';
    model.UserIds = [];
    /*for (let i = 0; i < this.users.length; i++) {
      model.UserIds.push(this.users[i].UserID);
    }*/


    model.Statuses = [];
    model.IncludeSurveys = true;
    this.legendTitle = this.formGroup.controls.Aggregator.value;
    this.yAxisLabel = this.formGroup.controls.MessageType.value;
    this.showGraph = false;
    let matchid = this.formGroup.controls.Aggregator.value;
    this.messageService.getTranlogStats(model).subscribe(
      results => {
        this.statuses = [];
        this.results = [];
        this.messages = 0;
        this.segments = 0;
        this.stats = results;
        if (this.stats.length == 0) {
          this.nothingShowing = true;
          this.sb?.dismiss();
          return;
        }

        const days = this.getDays();
        this.nothingShowing = false;
        let temp = new TranLogStatsResults(this.stats);
        if (this.formGroup.controls.Aggregator.value == 5) {
          for (let i = 0; i < this.stats.length; i++) {
            this.addToSeries(this.stats[i], this.stats[i].GatewayName);
            this.addToStatus(this.stats[i]);
          }
        }
        else {
          for (let i = 0; i < temp.results.length; i++) {
            let match = "";
            switch (matchid) {
              case 1:
                let time = temp.results[i].Date;
                if (days > 7) {
                  time = temp.results[i].Date.split("T")[0];
                }
                match = time;
                break;
              case 2:
                match = temp.results[i].Country;
                break;
              case 3:
                let account = temp.results[i].Account.split("(");
                match = account[0];
                break;
              case 4:
                match = temp.results[i].Survey;
                break;
              case 5:
                match = this.stats[i].Account;
                break;
            }

            this.addToStatus(temp.results[i]);
            this.addToSeries(temp.results[i], match);
          }
        }

        this.pivot.dataSource.data = this.isAdminUser ? this.stats : temp;
        this.setPivotMeasures();
        let aggregators = this.calculateAggregators();
        this.pivot.slice.rows = aggregators.rows;
        this.dataReady = true;
        this.pivotResults = this.pivot;
        if (this.pivotTable != null && this.pivotTable != undefined) {
          this.pivotTable.report = this.pivotResults;
        }

        this.showGraph = true;
        this.sb?.dismiss();
      }
    );
  }

  setPivotMeasures() {
    let name = this.formGroup.controls.MessageType.value;
    this.pivot.slice.measures[0].uniqueName = name;
    this.pivot.slice.measures[1].formula = "percentofrow(\"" + name + "\")";
  }

  setPivotMeasure() {
    this.setPivotMeasures();
    this.pivotResults = this.pivot;
    if (this.pivotTable != null && this.pivotTable != undefined) {
      this.pivotTable.report = this.results;
    }
  }

  myColors(event) {
    let receiptValues: any[] = [
      { "name": "Delivered", "value": 0, "color": "#00a0b0" },
      { "name": "Submitted to Carrier", "value": 1, "color": "#edc951" },
      { "name": "Buffered For Retry", "value": 2, "color": "#edc951" },
      { "name": "Failed", "value": 3, "color": "#cc2a36" },
      { "name": "Unknown", "value": 4, "color": "#cc2a36" },
      { "name": "System Error", "value": 5, "color": "#cc2a36" },
      { "name": "Blacklisted", "value": 6, "color": "#000000" },
      { "name": "Destination Busy", "value": 7, "color": "#eb6841" },
      { "name": "Number UnRoutable", "value": 8, "color": "#cc2a36" },
      { "name": "Destination Unavailable", "value": 9, "color": "#eb6841" },
      { "name": "Blocked", "value": 10, "color": "#eb6841" },
      { "name": "Success", "value": 11, "color": "#00a0b0" },
      { "name": "Invalid Destination Number", "value": 12, "color": "#cc2a36" },
      { "name": "Inbound", "value": 13, "color": "#83d0c9" },
      { "name": "OnStop", "value": 14, "color": "#ffffff" },
      { "name": "Read", "value": 15, "color": "#00a0b0" },
      { "name": "Invalid Source", "value": 16, "color": "#cc2a36" },
      { "name": "Undelivered", "value": 17, "color": "#eb6841" },
      { "name": "Content Related Error", "value": 18, "color": "#cc2a36" },
      { "name": "No Longer Active", "value": 19, "color": "#cc2a36" },
      { "name": "Network Error", "value": 20, "color": "#cc2a36" },
      { "name": "Age Restriction", "value": 21, "color": "#eb6841" },
      { "name": "Insufficient Funds", "value": 22, "color": "#eb6841" },
      { "name": "Regulation", "value": 23, "color": "#eb6841" },
      { "name": "Throttled", "value": 24, "color": "#eb6841" },
      { "name": "Equipement Permenent Error", "value": 25, "color": "#cc2a36" },
      { "name": "Equipement Temporary Error", "value": 26, "color": "#eb6841" },
      { "name": "Rejected", "value": 27, "color": "#cc2a36" },
      { "name": "Invalid Receipt Request", "value": 28, "color": "#edc951" },
      { "name": "Unreachable", "value": 29, "color": "#eb6841" },
      { "name": "Spam", "value": 30, "color": "#cc2a36" },
      { "name": "Queued for Submission", "value": 31, "color": "#edc951" },
      { "name": "Expired", "value": 32, "color": "#eb6841" }
    ];
    for (let i = 0; i < receiptValues.length; i++) {
      if (receiptValues[i].name == event) {
        return receiptValues[i].color;
      }
    }

    return "#edc951";
  }

  getColor(index: number) {
    let name = this.statuses[index].ReceiptStatusDescription;
    for (let i = 0; i < this.receiptValues.length; i++) {
      if (this.receiptValues[i].name == name) {
        return this.receiptValues[i].color;
      }
    }

    return "#edc951";
  }

  getStatValue(index: number) {
    switch (this.formGroup.controls.MessageType.value) {
      case "Messages":
        return this.statuses[index].Messages;
      case "Segments":
        return this.statuses[index].Segments;
      case "Percentage":
        return this.statuses[index].Segments;
    }

    return 0;
  }

  getTotalValue() {
    switch (this.formGroup.controls.MessageType.value) {
      case "Messages":
        return this.messages;
      case "Segments":
        return this.segments;
      case "Percentage":
        return "100%";
    }

    return 0;
  }

  addToStatus(stats: TranlogUserStatsModel) {
    for (let i = 0; i < this.statuses.length; i++) {
      if (this.statuses[i].ReceiptStatusDescription == stats.ReceiptStatusDescription) {
        this.statuses[i].Messages += stats.Messages;
        this.messages += stats.Messages;
        this.statuses[i].Segments += stats.Segments;
        this.segments += stats.Segments;
        return;
      }
    }

    this.messages += stats.Messages;
    this.segments += stats.Segments;
    this.selectedStatuses.push(true);
    this.statuses.push(stats);
  }

  changedAll(event: MatCheckboxChange) {
    if (this.allChange) {
      return;
    }

    this.allChange = true;
    for (let i = 0; i < this.selectedStatuses.length; i++) {
      this.selectedStatuses[i] = event.checked;
    }

    this.allChange = false;
    this.buildSeries();
  }

  changedStatus(event: MatCheckboxChange, index: number) {
    this.selectedStatuses[index] = event.checked;
    let checked = true;
    for (let i = 0; i < this.selectedStatuses.length; i++) {
      if (!this.selectedStatuses[i]) {
        checked = false;
        break;
      }
    }

    if (this.allChange) {
      this.selectAll.checked = checked;
    }
    else {
      this.allChange = true;
      this.selectAll.checked = checked;
      this.allChange = false;
    }

    if (!this.allChange) {
      this.buildSeries();
    }
  }

  addToSeries(stats: TranlogUserStatsModel, match: string) {
    if (!this.isChecked(stats)) {
      return;
    }

    let value = 0;
    switch (this.formGroup.controls.MessageType.value) {
      case "Messages":
        value = stats.Messages;
        break;
      case "Segments":
        value = stats.Segments;
        break;
      case "Percentage":
        value = stats.Segments;
        break;
    }

    for (let i = 0; i < this.results.length; i++) {
      let name = match.includes("(") ? [ this.results[i].name ] : this.results[i].name.split("(");
      if (name[0] == match) {
        for (let j = 0; j < this.results[i].series.length; j++) {
          if (this.results[i].series[j].name == stats.ReceiptStatusDescription) {
            this.results[i].series[j].value += value;
            return;
          }
        }

        this.results[i].series.push({ "name": stats.ReceiptStatusDescription, "value": value });
        return;
      }
    }

    let data = { "name": match, "series": [] };
    data.series.push({ "name": stats.ReceiptStatusDescription, "value": value });
    this.results.push(data);
  }

  isChecked(stats: TranlogUserStatsModel): boolean {
    for (let i = 0; i < this.statuses.length; i++) {
      if (this.statuses[i].ReceiptStatusDescription == stats.ReceiptStatusDescription) {
        return this.selectedStatuses[i];
      }
    }

    return false;
  }

  buildSeries() {
    this.sb = this.snackbar.openFromComponent(SnackbarDefaultComponent);
    this.legendTitle = this.formGroup.controls.Aggregator.value;
    this.yAxisLabel = this.formGroup.controls.MessageType.value;
    this.showGraph = false;
    this.results = [];
    const days = this.getDays();
   if (this.formGroup.controls.Aggregator.value == 5) {
      for (let i = 0; i < this.stats.length; i++) {
        this.addToSeries(this.stats[i], this.stats[i].GatewayName);
      }
    }
    else {
      let temp = new TranLogStatsResults(this.stats);
      let matchid = this.formGroup.controls.Aggregator.value;
      for (let i = 0; i < temp.results.length; i++) {
        let match = "";
        switch (matchid) {
          case 1:
            match = temp.results[i].Date;
            if (days > 7) {
              match = temp.results[i].Date.split("T")[0];
            }

            break;
          case 2:
            match = temp.results[i].Country;
            break;
          case 3:
            let account = temp.results[i].Account.split("(");
            match = account[0];
            break;
          case 4:
            match = temp.results[i].Survey;
            break;
          case 5:
            match = temp.results[i].Country;
            break;
        }

        this.addToSeries(temp.results[i], match);
      }
    }

    if (this.formGroup.controls.MessageType.value == "Percentage") {
      this.buildPercentages();
    }

    this.showGraph = true;
    this.sb?.dismiss();
  }

  buildPercentages() {
    for (let i = 0; i < this.results.length; i++) {
      let total = 0;
      for (let j = 0; j < this.results[i].series.length; j++) {
        total += this.results[i].series[j].value;
      }

      for (let j = 0; j < this.results[i].series.length; j++) {
        let value = this.results[i].series[j].value;
        this.results[i].series[j].value = Math.floor(value * 100 / total);
      }
    }
  }

  onDeactivate(event: any) {

  }

  onActivate(event: any) {

  }

  onSelect(event: any) {


  }
}
