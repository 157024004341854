import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from '../authentication.Service';
import { SurveyService } from '../survey.Service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { version } from 'os';
import { ZendeskUtil } from '../utils';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  formGroup: FormGroup;
  errorMsg: string = "";
  displayError: boolean;
  hide = true;
  public displayForm: boolean = true;
  deviceType: string = "";
  loggingIn: boolean = false;
  showCaptcha: boolean = false;
  captchaLevel: number = 1.9;
  siteKey: string = "6LdvQ5AqAAAAAHInEePSGKXIs6O_WlDbeSbdmqbx";

  zendeskCheck: string[] = [
    "https://",
    "https://localhost",
    "https://staging.",
    "https://pg.",
    "https://ire.",
    "https://sg.",
    "https://ldn.",
    "https://ams.",
    "https://lbg"
  ];

  constructor(private authService: AuthenticationService,
    private recaptchaV3Service: ReCaptchaV3Service,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private router: Router) {
    this.displayError = false;
    this.authService.checkToken(this.route.snapshot.queryParamMap.get("token") ?? "").subscribe({
      next: (result) => {

      },
      error: (error) => {
        this.generateError("Unable to reset the password at this point in time. Please consider requesting a password reset email again.");
      }
    });
    this.formGroup = new FormGroup({
      newPassword: new FormControl("", [Validators.required]),
      confirmPassword: new FormControl("", [Validators.required]),
      ipAddress: new FormControl(""),
      deviceType: new FormControl("")
    });
  }

  resetPassword() {
    if (this.formGroup.invalid) {
      return;
    }

    this.recaptchaV3Service.execute('resetPassword').subscribe((token) => this.handleToken(3, token));
  }

  generateError(error: string) {
    this.errorMsg = error;
    this.displayError = true;
    this.displayForm = false;
  }

  handleToken(version: number, token: string) {

    let deviceInfo = this.deviceService.getDeviceInfo();
    this.deviceType = deviceInfo.userAgent;
    this.authService.getIpAddress().subscribe(
      {
        next: (result) => {
          this.formGroup.controls.ipAddress.setValue(result.ip);
          this.resetPassword1a(version, token);
        },
        error: (error) => {
          this.formGroup.controls.ipAddress.setValue("");
          this.resetPassword1a(version, token);
        }
      });
    return false;
  }

  resetPassword1a(version: number, token: string) {
    let ipAddress = this.formGroup.controls.ipAddress.value;
    this.authService.testRcToken(version, token, ipAddress).subscribe(
      {
        next: (result) => {
          if (result.success && (version == 2 || result.score < this.captchaLevel)) {
            if (version == 3) {
              this.loggingIn = false;
              this.showCaptcha = true;
              return;
            }
          }

          this.resetPassword1b(version, ipAddress);
        },
        error: (error) => {
          this.generateError('We are unable to reset your password. Please contact Client Services for further help.');
        }
      });

  }

  resetPassword1b(version: number, token: string) {
    this.authService.resetPassword(this.route.snapshot.queryParamMap.get("token") ?? "", this.formGroup.value.newPassword,
      this.deviceType, this.formGroup.controls.ipAddress.value)
      .subscribe({
        next: (result) => {
          if (result.Status == 0) {
            this.authService.setAccessToken(result.Token);
            this.router.navigate(['../2fa']);
            return;
          }

          if (result.Status == 2) {
            this.authService.setAccessToken(result.Token);
            this.router.navigate(['../2fasetup']);
            return;
          }

          this.loggingIn = true;
          this.checkToken((result as any).Token);
        }
      });
  }

  resolvedCaptcha(event: any) {
    this.handleToken(2, event);
  }

  erroredCaptcha(event: any) {
    let a = event;
  }

  checkToken(token: string) {
    this.authService.setAccessToken(token);
    let page = '';
    if (this.authService.isAuthorized(['Admin'])) {
      page = '/system-dashboard';
      this.signIntoZenDesk(page);
    }
    else if (this.authService.isAuthorized(['developer'])) {
      page = '/smshome/keys';
      this.signIntoZenDesk(page);
    }
    else {
      this.surveyService.getSurveyCount()
        .subscribe(response => {
          if (response > 0) {
            page = '/surveydashboard';
          } else {
            page = '/startfromtemplate';
          }

          this.signIntoZenDesk(page);
        },
          error => {
            if (error.status === 401) {
              this.router.navigate(['../']);
            }
          });
    }
  }

  private signIntoZenDesk(redirectTo: string) {
    let rt = window.location.href.toString();
    for (let i = 0; i < this.zendeskCheck.length; i++) {
      if (rt.startsWith(this.zendeskCheck[i])) {
        this.router.navigate(['..' + redirectTo]);
        return;
      }
    }

    this.authService.zendeskCheck().subscribe(
      result => {
        ZendeskUtil.signin(result.Value, redirectTo);
      },
      error => {
        console.error("There was a problem with the login for Zendesk:" + error)
      });
  }

  ngOnInit(): void {
  }
}
