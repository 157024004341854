<mat-accordion>
  <mat-expansion-panel *ngIf="canEdit" hideToggle [expanded]="transferType == 'sms'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          Test Messaging
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-send></app-send>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle [expanded]="transferType == 'history'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          Message Analysis
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-traffic-analysis></app-traffic-analysis>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle [expanded]="transferType == 'history'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          {{isGlobal ? "Global " : ""}}Message Insights
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-message-history [system]="isGlobal"></app-message-history>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle [expanded]="transferType == 'history'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          Traffic Analysis
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-traffic-dashboard></app-traffic-dashboard>
    </div>
  </mat-expansion-panel>
  <!--mat-expansion-panel hideToggle [expanded]="transferType == 'network'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          Network / Country Lookup
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-manage-network></app-manage-network>
    </div>
  </!--mat-expansion-panel>

  <mat-expansion-panel hideToggle [expanded]="transferType == 'buynumber'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          New Number Connection Request
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-buynumber></app-buynumber>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle [expanded]="transferType == 'senderid'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          SenderID Registration
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-senderid></app-senderid>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle [expanded]="transferType == 'tfn'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          TFN Registration
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-tfn></app-tfn>
    </div>
  </mat-expansion-panel>
  <mat-expansion-panel hideToggle [expanded]="transferType == 'dlcbrand'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          10 DLC Registration
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-dlc-brand></app-dlc-brand>
    </div>
  </mat-expansion-panel>
  <!--<mat-expansion-panel hideToggle [expanded]="transferType == 'dlccampaign'">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="sms-tools-titlepanel-div">
          10 DLC_Campaign Registration
        </div>
      </mat-panel-title>
      <mat-panel-description>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div>
      <app-dlc-campaign></app-dlc-campaign>
    </div>
  </mat-expansion-panel>-->
</mat-accordion>
