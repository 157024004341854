<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav class="nav-sidenav" [mode]="(isHandset$ | async) ? 'over' : 'over'" autoFocus="false">
    <mat-nav-list>
      <div class="nav-navlist-container"
           [style]="(isHandset$ | async) ? 'top:40px; padding: 0 15px 0 15px;' : 'top:0px;'">
        <div style="width:100%">
          <div style="float:left">
            <img src="assets/img/favicon.png" style="padding-top:10px;height:30px" />
          </div>
          <div style="float:right">
            <div #dummy></div>
            <button mat-icon-button aria-label="Close" (click)="toggleSideMenu()"><mat-icon>close</mat-icon></button>
          </div>
        </div>
        <div *ngIf="showSurveyDashboard" joyrideStep="surveyDashboardStep" [stepContent]="surveyDashboardTourContent"
             class="nav-navlist-item-div">
          <a routerLink="/surveydashboard" class="nav-navlist" routerLinkActive="nav-is-active">
            <div class="nav-first-tile-div">
              <div><img src="assets/img/icon-view-list.png" /></div>&nbsp;
              <div><span>Survey dashboard</span></div>
            </div>
          </a>
        </div>
        <ng-template #surveyDashboardTourContent>
          <strong> Survey Dashboard </strong>
          <br />
          <br />
          Manage your surveys
        </ng-template>

        <div *ngIf="canEdit" joyrideStep="surveyTemplateStep" [stepContent]="surveyTemplateTourContent"
             class="nav-navlist-item-div">
          <a routerLink="/startfromtemplate" class="nav-navlist" routerLinkActive="nav-is-active">
            <div class="nav-first-tile-div">
              <div><img src="assets/img/icon-create-template.png" /></div>&nbsp;&nbsp;
              <div><span>Start from template</span></div>
            </div>
          </a>
        </div>
        <ng-template #surveyTemplateTourContent>
          <strong> Start from Template </strong>
          <br />
          <br />
          Use a pre-built expert survey script
        </ng-template>

        <div *ngIf="canEdit" joyrideStep="surveyScratchStep" [stepContent]="surveyScratchTourContent"
             class="nav-navlist-item-div">
          <a [routerLink]="" (click)="newSurveyFromScratchDialog()" class="nav-navlist" routerLinkActive="nav-is-active">
            <div class="nav-first-tile-div">
              <div><img src="assets/img/icon-create.png" /></div>&nbsp;&nbsp;
              <div><span>Start from scratch</span></div>
            </div>
          </a>
        </div>
        <ng-template #surveyScratchTourContent>
          <strong> Start from Scratch </strong>
          <br />
          <br />
          Build your own survey script
        </ng-template>

        <div *ngIf="canEdit && isAdmin()" joyrideStep="marketMessage" [stepContent]="marketingMessage"
             class="nav-navlist-item-div">
          <a [routerLink]="" (click)="newSurveyFromScratchDialog()" class="nav-navlist" routerLinkActive="nav-is-active">
            <div class="nav-first-tile-div">
              <div><img src="assets/img/icon-create.png" /></div>&nbsp;&nbsp;
              <div>
                <span>

                  Send a Marketing Message
                </span>
              </div>
            </div>
          </a>
        </div>
        <ng-template #marketingMessage>
          <strong> Send a Marketing Message </strong>
          <br />
          <br />
          Send your marketing message via our global network
        </ng-template>

        <div *ngIf="canEdit" joyrideStep="respondentsStep" [stepContent]="respondentsTourContent" (done)="onCloseMenu()"
             class="nav-navlist-item-div">
          <a [routerLink]="'/respondents'" class="nav-navlist" routerLinkActive="nav-is-active">
            <div class="nav-first-tile-div">
              <div><img src="assets/img/icon-users.png" /></div>&nbsp;&nbsp;
              <div><span>Respondents</span></div>
            </div>
          </a>
          <ng-template #respondentsTourContent>
            <strong> Respondents </strong>
            <br />
            <br />
            Upload and manage your Respondents
          </ng-template>
        </div>
        <div *ngIf="isSms()" class="nav-navlist-item-sms-div"
             [style]="(isHandset$ | async) ? 'margin-bottom: 0px;' : 'margin-bottom: 12px;'">
          <a [routerLink]="'/smshome'" class="nav-navlist" routerLinkActive="nav-is-active">
            <div class="nav-first-tile-div">
              <div><img src="assets/img/icon-sms-tools.png" /></div>&nbsp;&nbsp;
              <div><span>Messaging tools</span></div>
            </div>
          </a>
        </div>
        <div *ngIf="isTester() && canEdit" class="nav-navlist-item-sms-div"
             [style]="(isHandset$ | async) ? 'margin-bottom: 0px;' : 'margin-bottom: 12px;'">
          <a [routerLink]="'/communications'" class="nav-navlist" routerLinkActive="nav-is-active">
            <div class="nav-first-tile-div">
              <div><mat-icon style="padding-top:2px"><span style="color:#65AAFD;">view_quilt</span></mat-icon></div>&nbsp;&nbsp;
              <div><span>Communication templates</span></div>
            </div>
          </a>
        </div>
        <div *ngIf="canEdit && isAdmin()" class="nav-navlist-item-sms-div"
             [style]="(isHandset$ | async) ? 'margin-bottom: 0px;' : 'margin-bottom: 12px;'">
          <a [routerLink]="'/system-dashboard'" class="nav-navlist" routerLinkActive="nav-is-active">
            <div class="nav-first-tile-div">
              <div><mat-icon style="padding-top:2px"><span style="color:#65AAFD;">settings</span></mat-icon></div>&nbsp;&nbsp;
              <div><span>System dashboard</span></div>
            </div>
          </a>
        </div>
        <!--div *ngIf="isAdmin()" class="nav-navlist-item-sms-div"
             [style]="(isHandset$ | async) ? 'margin-bottom: 0px;' : 'margin-bottom: 12px;'">
          <button mat-button class="nav-admin-tools-btn" [matMenuTriggerFor]="menu">
            <mat-icon><span class="material-icons">admin_panel_settings</span></mat-icon>
            Admin tools
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="'/system-dashboard'">System Dashboard</button>
            <button mat-menu-item [routerLink]="'/routing-dashboard'">Message Routing Dashboard</button>
            <button mat-menu-item [routerLink]="'/communications'">Communications</button>
            <button mat-menu-item [routerLink]="'/manageapplications'">WhatsApp Applications</button>
            <button mat-menu-item [routerLink]="'/smshome/global'">Global Messaging</button>
            <button mat-menu-item [routerLink]="'/dataio'">Data IO</button>
            <button mat-menu-item [routerLink]="'/security'">Security Manager</button>
            <button mat-menu-item [routerLink]="'/users'">Users</button>
            <button mat-menu-item [routerLink]="'/system'">System</button>
            <button mat-menu-item [routerLink]="'/networkmanager'">Network Manager</button>
            <button mat-menu-item [routerLink]="'/adminorgs'">Organizations</button>
            <button mat-menu-item [routerLink]="'/cint-mapper'">CINT mapper</button>
            <button mat-menu-item [routerLink]="'/cint-pricing'">CINT pricing</button>
            <button mat-menu-item [routerLink]="'/fintrans'">Financial transactions</button>
            <button mat-menu-item [routerLink]="'/activenumber'">Check Number</button>
          </mat-menu>
        </!--div-->
        <div>
          <div style="font-size:10px;width:100%">
            <div style="margin:20px 5px 10px 5px">
              <span>&#169;{{ currentYear }} DIY Surveys Ltd. </span>
            </div>
            <div style="margin:10px 5px">
              <img alt="Static Badge" src="https://img.shields.io/badge/{{currentVersion}}-656565">
            </div>
            <div style="margin:10px auto">
              <div style="margin:5px;">
                <div>
                  <span><a (click)="goToLink('https://diysurveys.zendesk.com/')" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Docs' : 'Documentation'"></a></span>&nbsp;&nbsp;<br />
                  <span><a (click)="openTerms()" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Terms' : 'Terms of Service'"></a></span>&nbsp;&nbsp;
                  <span><a (click)="openPrivacy()" class="cursor-pt" [innerHTML]="(isHandset$ | async) ? 'Privacy' : 'Privacy Statement'"></a></span>&nbsp;&nbsp;
                </div>
              </div>
            </div>
            <div style="margin:5px">
              <a (click)="goToLink('https://www.linkedin.com/company/diy-surveys/')" style="cursor: pointer;"><img src="assets/img/linkedin-png-linkedin-icon-1600.webp" style="height: 20px; padding-right: 15px;" /></a>
            </div>
            <div style="margin:5px">
              {{ currentInstance }}
            </div>
          </div>
        </div>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav #usermenu class="nav-sidenav" mode="over" position="end" autoFocus="false" style="background-color:white !important">
    <mat-nav-list style="background-color: white">
      <div>
        <div>
          <div style="float:left;margin:20px">
            <div style="display:flex;flex-flow:row">
              <div>
                <app-avatar [size]="30" name="{{selectedOrg?.name}}" [media]="image" paddingTop="7px" paddingLeft="12px"></app-avatar>
                <mat-icon *ngIf="isOrganization() !== -1" class="orgAvatarIcon" style="position:relative !important;top:-35px;left:20px">supervised_user_circle</mat-icon>
              </div>
              <div [matTooltip]="selectedOrg?.name" [style]="'overflow:hidden;text-overflow:ellipsis;width:' + (isImpersonating() ? '155px' : '200px')">
                &nbsp;{{selectedOrg?.name}}
                <div *ngIf="selectedOrg?.email != '' && selectedOrg?.name != selectedOrg?.email" [matTooltip]="selectedOrg?.email" style="overflow:hidden;text-overflow:ellipsis;">&nbsp;{{selectedOrg?.email}}</div>
              </div>
            </div>
          </div>
          <div style="float:right">
            <div style="display:flex;flex-flow:row">
              <div *ngIf="isImpersonating() !== ''">
                <button mat-mini-fab color="primary" matTooltip=" Finish Impersonating " (click)="stopImpersonating()">
                  <mat-icon>supervisor_account</mat-icon>
                </button>
              </div>
              <div>
                <button mat-icon-button aria-label="Close" (click)="toggleUserMenu()"><mat-icon>close</mat-icon></button>
              </div>
            </div>
          </div>
        </div>
        <div style="clear:both">
          <mat-divider></mat-divider>
        </div>
        <mat-selection-list [multiple]="false" [hideSingleSelectionIndicator]="true">
          <div *ngFor="let org of orgList; let i = index" style="width:100%">
            <mat-list-option *ngIf="org.value != selectedOrg.value" mat-button (click)="makeSelection(i, $event)">
              <div class="fieldRowWithPadding">
                <div>
                  <img *ngIf="org.media != null" [src]="org.media | safe: 'resourceUrl'" alt="Profile Image" class="user-menu-avatarIcon">
                  <mat-icon *ngIf="org.media == null">{{org.icon}}</mat-icon>
                </div>
                <div>
                  &nbsp;{{org.name}}
                </div>
              </div>
            </mat-list-option>
          </div>
          <mat-divider></mat-divider>
          <mat-list-option [routerLink]="['/profile']">
            <div class="fieldRowWithPadding">
              <div><mat-icon>account_box</mat-icon></div>
              <div>&nbsp;My Account</div>
            </div>
          </mat-list-option>
          <mat-list-option [routerLink]="['/login']">
            <div class="fieldRowWithPadding">
              <div><mat-icon>exit_to_app</mat-icon></div>
              <div>&nbsp;Logout</div>
            </div>
          </mat-list-option>
          <mat-divider *ngIf="isImpersonating()"></mat-divider>
          <mat-list-option (click)="goToLink('https://diysurveys.zendesk.com/')">
            <div class="fieldRowWithPadding">
              <div><mat-icon>help</mat-icon></div>
              <div>&nbsp;DIY Surveys Support</div>
            </div>
          </mat-list-option>
          <mat-list-option (click)="goToLink('https://apidocs.diysurveys.com/')">
            <div class="fieldRowWithPadding">
              <div><mat-icon>menu_book</mat-icon></div>
              <div>&nbsp;DIY Surveys API Documentation</div>
            </div>
          </mat-list-option>
        </mat-selection-list>
        <mat-divider></mat-divider>
        <div style="padding:20px">
          Last Logged In {{lastLoggedIn | date:'dd/MM/yy, hh:mm a':'BST'}}
        </div>
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content">
    <mat-toolbar class="nav-toolbar-container mat-elevation-z0" style="border-bottom: 1px solid #ddd">
      <button *ngIf="showBack" mat-icon-button #backButton (click)="onClick()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <button *ngIf="!showBack" mat-icon-button (click)="toggleSideMenu()">
        <mat-icon>menu</mat-icon>
      </button>
      <div class="ml-mr-5">
        <a (click)="goToHome()" class="cursor-pt"><img src="assets/img/logo.svg" class="toolbar-logo" /></a>
      </div>
      <div class="ml-10" *ngIf="displayName && showBack">
        <div class="edit-toolbar-name-div">{{nameType}}</div>
        <div class="edit-toolbar-edit-div" [hidden]="!displayName">
          <button (click)="openBasicDialog()" mat-flat-button>{{name}}<mat-icon class="edit-toolbar-edit-icon">create</mat-icon></button>
        </div>
      </div>
      <div *ngIf="(!showBack && displayName) || (showBack && !displayName)" style="margin-left:10px">
        {{name}}
      </div>
      <span class="toolbar-spacer"></span>
      <div class="nav-header-right-container" [class]="(isHandset$ | async) ? 'noDisplay' : ''">
        <div *ngIf="isTester()" style="border: 1px solid #cccccc;border-radius: 5px;width:35px;padding: 6px 0 0 5px;margin-right:10px;cursor:pointer">
          <mat-icon (click)="gotoInbox()" matTooltip="Inbox" aria-hidden="false" matBadge="0" matBadgeColor="warn" matBadgeOverlap="false" [matBadgeHidden]="messages === 0">inbox</mat-icon>
        </div>
        <div>
          <div>
            <div class="user-menu-button-div">
              <button *ngIf="selectedOrg" [matTooltip]="selectedOrg.name" mat-button class="user-profile-name" (click)="toggleUserMenu()">
                <app-avatar [size]="30" name="{{selectedOrg.name}}" [media]="image"></app-avatar>
                <mat-icon *ngIf="isOrganization() !== -1" class="orgAvatarIcon">supervised_user_circle</mat-icon>
              </button>
              <mat-icon *ngIf="isImpersonating() !== ''" matTooltip=" Impersonating " style="font-size:15px;top:20px;right:-50px">supervisor_account</mat-icon>
            </div>
          </div>
          <!--app-user-menu></!--app-user-menu-->
        </div>
      </div>
    </mat-toolbar>
    <div [class]="editType == 'survey' ? '' : 'nav-main-content'" style="width:100%">
      <router-outlet></router-outlet>
    </div>
    <app-newfooter></app-newfooter>
  </mat-sidenav-content>
</mat-sidenav-container>

